import { AgGridAngular } from '@ag-grid-community/angular';
import {
  CellPosition,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnState,
  GetLocaleTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  HeaderPosition,
  IRowNode,
  NavigateToNextCellParams,
  NavigateToNextHeaderParams,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
  SelectionChangedEvent,
  SideBarDef,
  TabToNextCellParams,
  TabToNextHeaderParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { RowSelectionOptions } from '@ag-grid-community/core/dist/types/src/entities/gridOptions';
import { DOCUMENT, NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  Inject,
  Injector,
  model,
  output,
  Renderer2,
  signal,
  untracked,
  ViewChild,
  ViewContainerRef,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ColorsUtils, ConditionProcessorUtil } from '@iot-platform/iot-platform-utils';
import { Filter, MasterViewEngineEvent, Pagination } from '@iot-platform/models/common';
import {
  ExportParams,
  GridNavigationType,
  HeaderType,
  I4BButtonColumn,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BColumnRowGroupConfiguration,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  I4BGridSort,
  I4BSelectionColumn
} from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { debounce, get } from 'lodash';
import { GridExportDialogComponent } from '../../components/grid-export/grid-export-dialog/grid-export-dialog.component';
import { PaginatorComponent } from '../../components/pagination/paginator/paginator.component';

import {
  CELL_CONCEPTS_WITH_IS_DISPLAY_OPTION,
  FRAMEWORK_COMPONENTS,
  GRID_GROUP_HEADER_HEIGHT,
  GRID_HEADER_HEIGHT,
  GRID_ROW_HEIGHT
} from '../../constants/grid-settings.constants';
import { AgGridNavigationHelpers } from '../../helpers/ag-grid-navigation.helpers';
import { GridFiltersHelpers } from '../../helpers/grid-filters.helpers';
import { GridExportService } from '../../services/grid-export.service';

import { GridsDbActions } from '../../state/actions';

@Component({
  selector: 'grid-engine-grid-page',
  templateUrl: './grid-page.component.html',
  imports: [AgGridAngular, PaginatorComponent, NgClass],
  styleUrls: ['./grid-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridPageComponent implements AfterViewInit {
  grid = model<I4BGrid<I4BGridOptions, I4BGridData>>();
  gridSort = model<I4BGridSort[]>([]);
  currentFilters = model<Filter[]>([]);
  userPermissions = model<any>();
  visibleNodeId = model<string>();
  hidePaginator = model<boolean>(false);
  disableAutoFit = model<boolean>(false);
  loading = model<boolean>(false);
  rowGroupColors = signal([]);
  dispatchMasterViewEngineEvent = output<any>();
  dispatchGridEvent = output<any>();
  pageChange = output<Pagination>();
  sortChange = output<{
    state: ColumnState[];
    grid: I4BGrid<I4BGridOptions, I4BGridData>;
  }>();
  selectRow = output<RowClickedEvent>();
  rowSelected = output<RowSelectedEvent>();

  filters: WritableSignal<Filter[]> = signal([]);
  mvName: WritableSignal<string> = signal('');
  columnDefs: WritableSignal<ColDef[]> = signal([]);
  rowData: WritableSignal<any[]> = signal([]);
  rowClassRules: WritableSignal<any> = signal({});
  defaultColDef = {
    // suppressHeaderMenuButton: true,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 80,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    // enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true
  };
  sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        toolPanelParams: {
          suppressExpandAll: true,
          suppressFilterSearch: true
        }
      }
    ],
    hiddenByDefault: true,
    position: 'right',
    defaultToolPanel: 'columns'
  };
  readonly dialog: MatDialog = inject(MatDialog);
  gridOptions: GridOptions = {
    suppressTouch: true,
    cellSelection: true,
    copyHeadersToClipboard: false,
    columnMenu: 'legacy',
    maintainColumnOrder: false,
    rowSelection: {
      mode: 'multiRow',
      selectAll: 'filtered',
      copySelectedRows: true,
      enableClickSelection: false,
      checkboxes: false,
      headerCheckbox: false,
      groupSelects: 'filteredDescendants'
    },
    suppressMenuHide: true,
    headerHeight: GRID_HEADER_HEIGHT,
    rowHeight: GRID_ROW_HEIGHT,
    groupHeaderHeight: GRID_GROUP_HEADER_HEIGHT,
    components: FRAMEWORK_COMPONENTS,
    rowBuffer: 20,
    debounceVerticalScrollbar: true,
    animateRows: false,
    enableCellTextSelection: true,
    pagination: false,
    autoGroupColumnDef: {
      headerName: '',
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: FRAMEWORK_COMPONENTS[I4BCellType.CUSTOM_ROW_GROUP],
        rowData: this.rowData,
        rowGroupColors: this.rowGroupColors,
        autoSize: true,
        dispatchEvent: (event: MasterViewEngineEvent) => {
          this.dispatchMasterViewEngineEvent.emit(event);
        }
      },
      pinned: null,
      cellClassRules: {
        'ag-row-group-inner-renderer-cell__group_leaf': (params) => !params.node?.group
      }
    },
    groupDisplayType: 'singleColumn',
    rowGroupPanelShow: 'never',
    groupAllowUnbalanced: true,
    groupDefaultExpanded: 1,
    onRowDoubleClicked: debounce((event) => {
      if (!event?.node?.group) {
        this.dispatchMasterViewEngineEvent.emit({
          type: 'open',
          options: { selected: event.context },
          rawData: event.data
        });
      }
    }, 150),
    onRowClicked: debounce((event) => {
      this.onRowClicked(event);
      this.dispatchGridEvent.emit({
        event
      });
    }, 150),
    onRowSelected: debounce((event: RowSelectedEvent<any>) => {
      this.onRowSelected(event);
    }, 150),
    onSelectionChanged: (event) => {
      this.onSelectionChanged(event);
    },
    onColumnMoved: (event: ColumnMovedEvent) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    onColumnResized: (event: ColumnResizedEvent) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    getRowId: (params) => params.data.id,
    onModelUpdated: debounce(() => {
      this.autoSizeAll();
      this.sizeColumnsToFit();
    }, 300),
    onVirtualColumnsChanged: debounce(() => {
      this.setRowGroupColors();
    }, 300),
    onColumnRowGroupChanged: (event: ColumnRowGroupChangedEvent) => {
      if (event.source === 'toolPanelUi') {
        this.onSortChange();
      }
    }
  };
  @ViewChild('exportViewRef', { read: ViewContainerRef }) protected readonly exportViewRef: ViewContainerRef;
  protected gridApi: GridApi;
  protected readonly renderer: Renderer2 = inject(Renderer2);
  protected readonly translateService: TranslateService = inject(TranslateService);
  @Inject(DOCUMENT) protected readonly document: Document = inject(DOCUMENT);
  protected readonly gridExportService: GridExportService = inject(GridExportService);
  protected readonly store: Store = inject(Store);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly injector: Injector = inject(Injector);

  onGridChangeEffect() {
    effect(
      () => {
        this.userPermissions();
        const grid = this.grid();
        const loading = this.loading();
        untracked(() => {
          if (grid && !loading) {
            grid.export = this.exportData.bind(this);
            this.rowClassRules.set({ ...get(grid, ['gridOptions', 'rowClassRules']) });
            this.rowData.set([...get(grid, ['data', 'response', 'data'], [])]);
            if (grid?.columns) {
              this.initColumnDefs(grid);
            }
          }
        });
      },
      { injector: this.injector }
    );
  }

  onColumnDefChangeEffect() {
    effect(
      () => {
        const columnDefs = this.columnDefs();
        untracked(() => {
          if (columnDefs?.length) {
            const grid = this.grid();
            const rowGroupColor = get(grid, ['gridOptions', 'rowGroup', 'color']);
            if (rowGroupColor) {
              const shadesCount = Math.max(columnDefs.filter((c) => c.rowGroup).length, 10);
              const colors = ColorsUtils.getShadesOfColor(rowGroupColor, shadesCount, 0.3, 2.5);
              this.rowGroupColors.set([...colors]);
            }
          }
        });
      },
      { injector: this.injector }
    );
  }

  onGridSortChangeEffect() {
    effect(
      () => {
        const loading = this.loading();
        const gridSort = this.gridSort();
        if (!loading) {
          this.setSort(gridSort);
        }
      },
      { injector: this.injector }
    );
  }

  onRowDataChangeEffect() {
    effect(
      () => {
        const rowData = this.rowData();
        untracked(() => {
          const grid = this.grid();
          const rowGroupOptions: any = get(grid, ['gridOptions', 'rowGroup']);
          const gridOptions: any = {
            rowData,
            autoGroupColumnDef: {
              ...this.gridOptions.autoGroupColumnDef,
              headerName: this.translateService.instant(get(rowGroupOptions, ['headerName'], ' ')),
              pinned: get(rowGroupOptions, ['pinned'], null)
            },
            groupDisplayType: get(rowGroupOptions, ['groupDisplayType'], 'singleColumn'),
            rowGroupPanelShow: get(rowGroupOptions, ['rowGroupPanelShow'], 'never'),
            groupAllowUnbalanced: get(rowGroupOptions, ['groupAllowUnbalanced'], true),
            groupDefaultExpanded: get(rowGroupOptions, ['groupDefaultExpanded'], 1)
          };
          if (!!get(rowGroupOptions, ['displayBlanksFirst'])) {
            gridOptions.initialGroupOrderComparator = (params) => params.nodeA.key?.length - params.nodeB.key?.length;
          }
          if (grid?.gridOptions?.selectionColumn?.enabled) {
            const selColDef = this.getSelectionColumnDefinition(grid);
            if (selColDef) {
              gridOptions.rowSelection = {
                ...(this.gridOptions?.rowSelection as RowSelectionOptions),
                checkboxes: true,
                headerCheckbox: true
              };
              gridOptions.selectionColumnDef = selColDef;
            }
          }
          if (!rowGroupOptions?.enabled) {
            gridOptions.rowGroupPanelShow = 'never';
          }

          this.gridApi.updateGridOptions(gridOptions);
          const timeout = setTimeout(() => {
            this.autoSizeAll();
            this.sizeColumnsToFit();
            this.gridApi.refreshCells({ force: true });
            clearTimeout(timeout);
          }, 300);
        });
      },
      { injector: this.injector }
    );
  }

  autoSizeAll(skipHeader = false) {
    const columnIds: string[] = ['ag-Grid-AutoColumn'];
    this.gridApi?.getColumns?.()?.forEach((column) => {
      if (column.getColDef()?.cellRendererParams?.autoSize) {
        columnIds.push(column.getId());
      }
    });
    if (columnIds.length) {
      this.gridApi?.autoSizeColumns?.(columnIds, skipHeader);
    }
    this.gridApi.refreshHeader();
  }

  getLocaleText = (params: GetLocaleTextParams) => this.translateService.instant(`AG_GRID.${params.key}`);

  ngAfterViewInit(): void {
    this.gridExportService.setViewRef(this.exportViewRef);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.onRowDataChangeEffect();
    this.onGridSortChangeEffect();
    this.onColumnDefChangeEffect();
    this.onGridChangeEffect();
    this.setFlexColumnClass();
  }

  setRowGroupColors() {
    if (this.gridApi) {
      const rowGroupColors = this.rowGroupColors();
      if (Object.keys(rowGroupColors)?.length) {
        const rowGroupPanel = document.querySelector('.ag-column-drop-wrapper .ag-column-drop');
        const dropCells = rowGroupPanel?.querySelectorAll('.ag-column-drop-list .ag-column-drop-cell');
        if (rowGroupPanel && dropCells) {
          this.gridApi.getRowGroupColumns().forEach((_, index) => {
            dropCells.forEach((cell: any) => {
              const posinset = cell.getAttribute('aria-posinset');
              if (index + 1 === Number(posinset)) {
                const color = rowGroupColors[index];
                this.renderer.setStyle(cell, 'background-color', color?.hex);
                if (!color?.isReadable) {
                  this.renderer.addClass(cell, 'ag-column-drop-cell--not-readable');
                }
              }
            });
          });
        }
      }
    }
  }

  onFirstDataRendered(): void {
    const visibleNodeId = this.visibleNodeId();
    if (visibleNodeId) {
      this.scrollTo(this.gridApi.getRowNode(visibleNodeId)?.rowIndex);
    }
  }

  onPageChange(pagination: Pagination): void {
    this.pageChange.emit(pagination);
    this.scrollTo(0);
  }

  scrollTo(index: number, position: 'top' | 'bottom' | 'middle' | null = 'top'): void {
    this.gridApi.ensureIndexVisible(index || 0, position);
  }

  onGridColumnsChanged() {
    if (this.disableAutoFit() === false) {
      this.sizeColumnsToFit();
    }
    this.initHeaderSize();
  }

  sizeColumnsToFit(): void {
    const timeout = setTimeout(() => {
      const viewports = this.document.querySelectorAll('[data-ref="eViewport"].ag-center-cols-viewport');
      viewports?.forEach((viewport: HTMLElement) => {
        const container: HTMLElement = viewport?.querySelector('[data-ref="eContainer"].ag-center-cols-container');
        if (viewport && container && container.offsetWidth > 0 && container.offsetWidth - 1 < viewport.offsetWidth) {
          this.gridApi?.sizeColumnsToFit?.();
        }
      });
      clearTimeout(timeout);
    }, 0);
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    const rawData: IRowNode[] = event.api.getSelectedNodes().filter((item) => !item?.group);
    this.dispatchMasterViewEngineEvent.emit({
      type: 'selectionChanged',
      rawData
    });
  }

  onRowSelected(event: RowSelectedEvent) {
    if (event.source === 'checkboxSelected') {
      event.api.refreshHeader();
      this.rowSelected.emit(event);
    } else if (event.source === 'uiSelectAllFiltered') {
      event.api.refreshHeader();
    }
  }

  onRowClicked(event: RowClickedEvent) {
    event.api.refreshHeader();
    this.selectRow.emit(event);
  }

  /**
   *
   * Grid navigation
   */
  navigateToNextHeader = (params: NavigateToNextHeaderParams): HeaderPosition | null => {
    const { nextHeader, processedNextHeader } = AgGridNavigationHelpers.processNextHeader(params);
    if (params.key !== GridNavigationType.KEY_DOWN.toString() && params.key !== GridNavigationType.KEY_UP.toString()) {
      return nextHeader;
    }
    if (processedNextHeader.headerRowIndex === -1) {
      this.onNavigateToNextRow(params.api, GridNavigationType.KEY_DOWN, 0);
    }
    return processedNextHeader === nextHeader ? null : processedNextHeader;
  };

  tabToNextHeader = (params: TabToNextHeaderParams): HeaderPosition | null =>
    AgGridNavigationHelpers.moveHeaderFocusUpDown(params.previousHeaderPosition, params.headerRowCount, params.backwards);

  tabToNextCell = (params: TabToNextCellParams): CellPosition | null => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    return AgGridNavigationHelpers.moveCellFocusUpDown(params.api, GridNavigationType.TAB_TO_NEXT_CELL, previousCell, suggestedNextCell, params.backwards);
  };

  navigateToNextCell = (params: NavigateToNextCellParams): CellPosition | null => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    const result = AgGridNavigationHelpers.moveCellFocusUpDown(params.api, params.key, previousCell, suggestedNextCell);
    if (result && (params.key === GridNavigationType.KEY_UP.toString() || params.key === GridNavigationType.KEY_DOWN.toString())) {
      this.onNavigateToNextRow(params.api, params.key, result.rowIndex);
    }
    return result;
  };

  onNavigateToNextRow(api: GridApi, type: string, nextRowIndex: number) {
    api.forEachNodeAfterFilterAndSort((node: RowNode) => {
      if (node.rowIndex === nextRowIndex) {
        this.dispatchGridEvent.emit({
          event: {
            type,
            data: node.data
          }
        });
      }
    });
  }

  onSortChange() {
    this.sortChange.emit({ state: this.gridApi.getColumnState(), grid: this.grid() });
  }

  /**
   *
   * End grid navigation
   */

  private initColumnDefs(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    let columnDefs = this.getColumnsDefinitions(grid.columns);
    if (grid.gridOptions.buttonColumn?.enabled) {
      const btnColDef = this.getButtonColumnDefinition(grid);
      if (btnColDef) {
        columnDefs = [...columnDefs, btnColDef]; // NEED TO ADD MULTI SELECT COLUMN
      }
    }
    this.columnDefs.set([...columnDefs]);
  }

  private setSort(gridSort: I4BGridSort[]) {
    let state = [...gridSort];
    const columnState = this.gridApi.getColumnState();
    if (columnState?.length) {
      state = columnState.map((colState) => {
        const found = gridSort.find((s) => s.colId === colState.colId);
        let newState = { ...colState, sort: null, sortIndex: null };
        if (found) {
          newState = {
            ...newState,
            ...found
          };
        }
        return newState;
      });
    }
    const timeout = setTimeout(() => {
      this.gridApi.applyColumnState({ state: state as ColumnState[] });
      clearTimeout(timeout);
    }, 0);
  }

  private initHeaderSize(): void {
    const headerElement: HTMLElement = this.document.querySelector('.ag-header.ag-focus-managed');
    if (headerElement && headerElement.offsetHeight > 40) {
      const height = GRID_HEADER_HEIGHT + GRID_GROUP_HEADER_HEIGHT + 10;
      this.renderer.setStyle(headerElement, 'height', `${height}px`);
    }
  }

  private exportData(params: ExportParams): void {
    const grid = this.grid();
    const columnDefs = this.columnDefs();
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated: false }));
    const {
      gridOptions: { filters, exportParams }
    } = grid;
    this.gridExportService.setGrid(grid);
    this.gridExportService.setParams({
      ...exportParams,
      filters,
      ...params
    });
    this.gridExportService.setColumnsDef([...columnDefs]);
    this.dialog
      .open(GridExportDialogComponent, {
        width: '500px',
        disableClose: true,
        data: { totalElements: params.totalElements }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated: true }));
      });
  }

  private getColumnsDefinitions(cols: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): ColDef[] {
    return cols.map((col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => this.getColumn(col));
  }

  private getSelectionColumnDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): ColDef | null {
    const userPermissions = this.userPermissions();
    const selectionColumn: I4BSelectionColumn = grid.gridOptions.selectionColumn.className;
    const buttonColum: I4BButtonColumn = grid.gridOptions.buttonColumn.className;
    const isSelectionVisible = ConditionProcessorUtil.processConditionsWithPermission(buttonColum?.configuration.cell.visibleConditions, userPermissions);
    if (selectionColumn && isSelectionVisible) {
      const selectionColDef = {
        pinned: 'left',
        lockPinned: true,
        suppressHeaderMenuButton: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        sortable: true,
        headerClass: 'ag-checkbox__pinned-left',
        cellClassRules: {
          'ag-checkbox__pinned-left': () => true
        }
      };
      return selectionColDef as ColDef;
    } else {
      return null;
    }
  }

  private getButtonColumnDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): ColDef | null {
    const userPermissions = this.userPermissions();
    const buttonColumn: I4BButtonColumn = grid.gridOptions.buttonColumn.className;
    const isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(buttonColumn?.configuration.cell.visibleConditions, userPermissions);
    if (buttonColumn && isCallToActionVisible) {
      const buttonColDef: ColDef = {
        field: buttonColumn.configuration.id,
        headerName: buttonColumn.header.displayName ? this.translateService.instant(buttonColumn.header.displayName) : buttonColumn.header.displayName,
        sortable: false,
        suppressHeaderMenuButton: true,
        width: 40,
        maxWidth: 40,
        pinned: 'right',
        lockPinned: true,
        headerComponent: HeaderType.CALL_TO_ACTION,
        headerComponentParams: {
          bulkActions: buttonColumn.configuration.cell.bulkActions,
          visibleConditions: buttonColumn.configuration.cell.visibleConditions,
          userPermissions,
          dispatchEvent: (event: MasterViewEngineEvent) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        },
        cellRenderer: I4BCellType.CALL_TO_ACTION,
        cellRendererParams: {
          actions: buttonColumn.configuration.cell.singleActions,
          userPermissions,
          dispatchEvent: (event: MasterViewEngineEvent) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        },
        valueGetter: (params) => JSON.stringify(params.data)
      };
      return buttonColDef;
    } else {
      return null;
    }
  }

  private getColumn(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    if (col.configuration?.children) {
      return this.getColumnDefinitionWithChildren(col);
    } else {
      return this.getColumnDefinition(col);
    }
  }

  private getColumnDefinition(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    const userPermissions = this.userPermissions();
    const headerName = col.options.overrideHeaderName ? col.options.overrideHeaderName : col.header.displayName;
    const gridRowGroupEnabled = !!get(this.grid(), ['gridOptions', 'rowGroup', 'enabled']);
    let enableRowGroup: boolean;
    let columnRowGroupConfig: any;
    if (col.configuration.children) {
      columnRowGroupConfig = (col?.configuration?.rowGroupParams ?? {}) as Partial<{
        [key: string]: Partial<I4BColumnRowGroupConfiguration>;
      }>;
      enableRowGroup = Object.keys(columnRowGroupConfig).some((k) => !!get(columnRowGroupConfig, [k, 'enabled']));
    } else {
      columnRowGroupConfig = col?.configuration?.rowGroupParams as Partial<I4BColumnRowGroupConfiguration>;
      enableRowGroup = gridRowGroupEnabled && !!columnRowGroupConfig?.enabled;
    }
    const gridSort = this.gridSort();
    const found = gridSort?.find((gs) => gs.colId === col.configuration.id);
    const rowGroup = (found && found.rowGroup) || (gridRowGroupEnabled && !!columnRowGroupConfig?.rowGroup);
    const hide = (enableRowGroup && rowGroup && columnRowGroupConfig?.hiddenWhenGrouping) ?? !!col.configuration?.hide;
    let columnDefinition: ColDef = {
      field: col.configuration.id,
      headerName: headerName ? this.translateService.instant(headerName) : headerName,
      rowGroup,
      enableRowGroup,
      hide,
      sortable: col.options.sortable,
      resizable: col.configuration?.resizable !== undefined && col.configuration?.resizable !== null ? col.configuration.resizable : true,
      pinned: col.options.pinned,
      suppressHeaderMenuButton: false,
      suppressHeaderFilterButton: true,
      cellClassRules: col.configuration.cell?.cellClassRules,
      headerComponent: col.header.type,
      headerComponentParams: {
        enableMenu: false,
        headerIcon: col.header.icon ?? '',
        headerTooltip: col.header.tooltip ?? '',
        showHeaderName: col.header.showHeaderName,
        enableSorting: col.header.enableSorting
      },
      comparator: col.configuration.comparator,
      cellRenderer: col.configuration.cell.type,
      cellRendererParams: {
        ...col.configuration?.rowGroupParams,
        autoSize: col.configuration?.autoSize ?? !col.configuration?.width,
        eventConfiguration: {
          type: col.configuration.cell?.event?.type ?? '',
          options: col.configuration.cell?.event?.options ?? ''
        },
        cellOptions: col.configuration.cell?.options ?? '',
        userPermissions,
        dispatchEvent: (event: MasterViewEngineEvent) => {
          this.dispatchMasterViewEngineEvent.emit(event);
        }
      }
    };

    if (!col.configuration?.autoSize && !!col.configuration?.width) {
      columnDefinition = {
        ...columnDefinition,
        width: col.configuration.width
      };
    }

    const enableFilter = get(col, ['configuration', 'filterParams', 'enabled'], false);
    if (enableFilter) {
      const filterDefinition = GridFiltersHelpers.getFilter(col);
      columnDefinition = {
        ...columnDefinition,
        headerComponentParams: {
          ...columnDefinition.headerComponentParams,
          enableMenu: true
        },
        ...filterDefinition,
        filterParams: {
          ...filterDefinition.filterParams,
          ...get(col, ['configuration', 'filterParams'])
        },
        suppressHeaderFilterButton: true,
        suppressFiltersToolPanel: true,
        menuTabs: [
          'filterMenuTab'
          // , 'generalMenuTab', 'columnsMenuTab'
        ]
      };
    }

    if (col.configuration.cell.valueGetter) {
      if (col.configuration.cell.valueGetter instanceof Function) {
        columnDefinition.valueGetter = col.configuration.cell.valueGetter.bind(this);
      } else {
        columnDefinition.valueGetter = (params: ValueGetterParams) => get(params.data, col.configuration.cell.valueGetter as string);
      }
    }
    if (col.configuration.cell.cellStyle) {
      columnDefinition.cellStyle = col.configuration.cell.cellStyle.bind(this);
    }
    return columnDefinition;
  }

  private getColumnDefinitionWithChildren(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    let children: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...col.configuration.children];

    if (CELL_CONCEPTS_WITH_IS_DISPLAY_OPTION.includes(col.configuration.concept)) {
      children = children.filter((child: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => child?.options?.isDisplayed);
    }

    return {
      ...this.getColumnDefinition(col),
      marryChildren: col.configuration.marryChildren,
      headerGroupComponent: HeaderType.CUSTOM_HEADER_GROUP,
      children: children.map((column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => ({
        ...this.getColumnDefinition(column),
        columnGroupShow: 'open'
      }))
    };
  }

  private setFlexColumnClass() {
    const mainSidenavContentEl = this.document.querySelector('.main-sidenav-content');
    if (mainSidenavContentEl) {
      this.renderer.addClass(mainSidenavContentEl, 'flex-column');
    }
  }
}
