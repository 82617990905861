import { DeviceConfiguration, DeviceConfigurationStatus, DeviceConfigurationTargetStatus, DeviceDetails, ModbusListItem } from '@iot-platform/models/dalia';
import { cloneDeep, get, uniq } from 'lodash';

export class DeviceConfigurationHelpers {
  static readonly mergeConfiguration = (configuration: DeviceConfiguration, status: DeviceConfigurationStatus) => {
    const config = cloneDeep(get({ configuration }, ['configuration'], {}) as DeviceConfiguration);
    if (status === DeviceConfigurationStatus.PENDING) {
      config.current = DeviceConfigurationHelpers.mergeConfigValues({ ...config }, 'pending');
    } else if (status === DeviceConfigurationStatus.PUBLISHED) {
      config.current = DeviceConfigurationHelpers.mergeConfigValues({ ...config }, 'target');
    }
    return config;
  };

  static readonly mergeDeviceConfiguration = (device: DeviceDetails): DeviceDetails => {
    const deviceConfiguration = get(device, 'configuration');
    const status = this.getConfigurationStatus(deviceConfiguration);
    const configuration = this.mergeConfiguration(deviceConfiguration, status);
    return { ...cloneDeep(device), configuration };
  };

  static readonly mergeConfigValues = (configuration: DeviceConfiguration, configKey: 'pending' | 'target') => {
    const currentConf = cloneDeep(get(configuration, ['current'], {}));
    const config = get(configuration, [configKey], {});
    Object.entries(config).forEach(([key, value]) => {
      if (!currentConf[key]) {
        currentConf[key] = {
          v: value
        };
      } else if (currentConf[key].v !== value) {
        currentConf[key].v = value;
      }
    });
    return currentConf;
  };

  static readonly getConfigurationStatus = (configuration: DeviceConfiguration): DeviceConfigurationStatus => {
    const pendingCount = this.getConfigCount(configuration, 'pending');
    const targetCount = this.getConfigCount(configuration, 'target');
    const isPending = !!pendingCount;
    const isTarget = !!targetCount;
    const targetStatus = this.getConfigurationTargetStatus(configuration);
    const isNotPendingOrNotRetrieved = !isPending || targetStatus === DeviceConfigurationTargetStatus.RETRIEVED;
    const hasError = targetStatus === DeviceConfigurationTargetStatus.ERROR;
    const isPublished = isTarget && (!isNotPendingOrNotRetrieved || hasError);
    let status = DeviceConfigurationStatus.CURRENT;
    if (isPublished) {
      status = DeviceConfigurationStatus.PUBLISHED;
    } else if (isPending) {
      status = DeviceConfigurationStatus.PENDING;
    }
    return status;
  };

  static readonly getConfigCount = (configuration: DeviceConfiguration, configKey: 'pending' | 'target') => {
    const currentConf = get(configuration, ['current'], {});
    const config = get(configuration, [configKey], {});
    return Object.entries(config).reduce((acc, [id, value]) => {
      if (currentConf[id]?.v !== value) {
        acc++;
      }
      return acc;
    }, 0);
  };

  static readonly getConfigurationTargetStatus = (configuration: DeviceConfiguration): DeviceConfigurationTargetStatus => get(configuration, ['targetStatus']);

  static readonly getStatusByKey = (key: string, configuration: DeviceConfiguration, pendingKeys: string[], targetKeys: string[]) => {
    let status = DeviceConfigurationStatus.CURRENT;
    const configurationStatus = DeviceConfigurationHelpers.getConfigurationStatus(configuration);
    if (configurationStatus === DeviceConfigurationStatus.PENDING && pendingKeys.includes(key)) {
      status = DeviceConfigurationStatus.PENDING;
    } else if (configurationStatus === DeviceConfigurationStatus.PUBLISHED && targetKeys.includes(key)) {
      status = DeviceConfigurationStatus.PUBLISHED;
    }
    return status;
  };

  static readonly parseIndex = (key: string): number => parseInt(key.replace(/\D/g, ''));

  static readonly getAvailableNextIndex = (indexes: number[], length: number): number => {
    const sortedIndexes = indexes.sort((a, b) => a - b);
    const array = Array.from({ length }, (_, i) => i + 1);
    return sortedIndexes.length ? array.find((n, index) => n !== sortedIndexes[index]) : 1;
  };

  static readonly getAvailableIndexes = (indexes: number[], length: number, currentIndex?: number): ModbusListItem[] => {
    const allIndexes = Array.from({ length }, (_, i) => i + 1);
    const eligibleIndexes = allIndexes.filter((index) => !indexes.includes(index));
    // Used for edit mode
    // Should contain current index in the list
    // When edit mode otherwise current index field will be undefined
    if (currentIndex) {
      eligibleIndexes.push(currentIndex);
    }
    return uniq(eligibleIndexes).map((value) => ({
      label: `${value}`,
      value
    }));
  };

  static readonly reduceConfiguration = (
    configuration: DeviceConfiguration,
    entityKey: string,
    targetConfiguration: 'current' | 'pending' | 'target',
    retrieveProperties: boolean
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const config = cloneDeep(get(configuration, [targetConfiguration], {}));
    // Remove updated properties that match variable index
    return Object.entries(config).reduce((acc, [key]) => {
      if (key.includes(entityKey) === retrieveProperties) {
        if (targetConfiguration === 'current') {
          acc[key] = get(config, [key, 'v']);
        } else {
          acc[key] = get(config, [key]);
        }
      }
      return { ...acc };
    }, {});
  };
}
