import { CommonGenericModel } from '../common/core/common-generic.model';
import { DeviceFileType } from './device-file-type.model';

export interface DeviceFile extends CommonGenericModel {
  type: DeviceFileType;
  download: boolean;
  lastReadSucceeded: string;
  lastReadTryFailed?: boolean;
  deviceId?: string;
  deviceIdentifier?: string;
  deviceName?: string;
}
