import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { DeviceTemplate } from '@iot-platform/models/dalia';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DeviceTemplatesActions } from '../actions';

export interface State extends BaseState<DeviceTemplate, Pagination, Filter> {
  deletionInProgress: boolean;
}

export const adapter: EntityAdapter<DeviceTemplate> = createEntityAdapter<DeviceTemplate>({
  selectId: (entity: DeviceTemplate) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  deletionInProgress: false,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const deviceTemplatesFeature = createFeature({
  name: 'deviceTemplates',
  reducer: createReducer(
    initialState,

    on(
      DeviceTemplatesActions.setFilters,
      (state: State, { filters }): State => ({
        ...state,
        filters: [...filters]
      })
    ),

    on(
      DeviceTemplatesActions.setCurrentTemplate,
      (state: State, { template }): State => ({
        ...state,
        entity: template
      })
    ),

    on(
      DeviceTemplatesActions.loadTemplateByIdSuccess,
      (state: State, { response }): State => ({
        ...state,
        entity: response
      })
    ),

    on(
      DeviceTemplatesActions.loadTemplates,
      (state: State, { request }): State => ({
        ...state,
        filters: GetUtils.get(request, 'filters', [])
      })
    ),
    on(
      DeviceTemplatesActions.loadTemplatesSuccess,
      (state: State, { response }): State =>
        adapter.setAll(GetUtils.get(response, 'data', []), {
          ...state,
          pagination: GetUtils.get(response, 'pagination')
        })
    ),

    on(
      DeviceTemplatesActions.createTemplateSuccess,
      DeviceTemplatesActions.duplicateTemplateSuccess
      , (state: State, { response }) =>
      adapter.addOne(response, {
        ...state,
        entity: response
      })
    ),


    on(DeviceTemplatesActions.editTemplateSuccess, (state: State, { response }) =>
      adapter.updateOne({ id: response.id, changes: response }, { ...state, entity: response })
    ),

    on(
      DeviceTemplatesActions.deleteTemplate,
      (state: State): State => ({
        ...state,
        deletionInProgress: true
      })
    ),
    on(DeviceTemplatesActions.deleteTemplateSuccess, (state: State, { response }) =>
      adapter.removeOne(response.id, {
        ...state,
        deletionInProgress: false
      })
    ),

    on(
      DeviceTemplatesActions.loadTemplates,
      (state: State): State => ({
        ...state,
        entity: null
      })
    ),

    on(
      DeviceTemplatesActions.loadTemplates,
      DeviceTemplatesActions.loadTemplateById,
      DeviceTemplatesActions.createTemplate,
      DeviceTemplatesActions.duplicateTemplate,
      DeviceTemplatesActions.editTemplate,
      DeviceTemplatesActions.deleteTemplate,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      DeviceTemplatesActions.loadTemplatesSuccess,
      DeviceTemplatesActions.loadTemplateByIdSuccess,
      DeviceTemplatesActions.createTemplateSuccess,
      DeviceTemplatesActions.duplicateTemplateSuccess,
      DeviceTemplatesActions.editTemplateSuccess,
      DeviceTemplatesActions.deleteTemplateSuccess,
      (state: State): State => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      DeviceTemplatesActions.loadTemplatesFailure,
      DeviceTemplatesActions.loadTemplateByIdFailure,
      DeviceTemplatesActions.createTemplateFailure,
      DeviceTemplatesActions.duplicateTemplateFailure,
      DeviceTemplatesActions.editTemplateFailure,
      DeviceTemplatesActions.deleteTemplateFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    )
  )
});
