import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-tab-image',
  template: `
    <ng-template>
      <ng-content />
    </ng-template>
  `
})
export class TabImageComponent {
  @ViewChild(TemplateRef) public imageTemplate!: TemplateRef<any>;
}
