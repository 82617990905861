import { AgGridAngular } from '@ag-grid-community/angular';
import { CellClassParams, ColDef, ColumnState, ICellRendererParams, SortChangedEvent, ValueGetterParams } from '@ag-grid-community/core';
import { RowSelectionOptions } from '@ag-grid-community/core/dist/types/src/entities/gridOptions';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, model, OnInit, output, signal, untracked, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConditionProcessorUtil, SortModelAdapter, SortUtil } from '@iot-platform/iot-platform-utils';
import { gridSortModel, Pagination, PlatformResponse } from '@iot-platform/models/common';
import { ExportParams, HeaderType, I4BCellType, I4BThresholdColumn, RichVariableColumn } from '@iot-platform/models/grid-engine';
import { get } from 'lodash';
import { GridExportDialogComponent } from '../../components/grid-export/grid-export-dialog/grid-export-dialog.component';
import { PaginatorComponent } from '../../components/pagination/paginator/paginator.component';
import { GridFiltersHelpers } from '../../helpers/grid-filters.helpers';
import { GridEngineService } from '../../services/grid-engine.service';
import { GridPageComponent } from '../grid-page/grid-page.component';

@Component({
  imports: [AgGridAngular, PaginatorComponent, NgClass],
  selector: 'grid-engine-grid-engine-component',
  templateUrl: './grid-engine.component.html',
  styleUrls: ['./grid-engine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridEngineComponent extends GridPageComponent implements OnInit {
  gridData = model<PlatformResponse>();
  gridMeta = model<any>();
  displayPagination = model<boolean>(true);
  masterViewType = model<{
    name: string;
    useStaticMetaData: boolean;
  }>(null);

  sortChange = output<any>();
  pagination: WritableSignal<Pagination> = signal({
    limit: 10,
    currentPage: 0,
    total: 0,
    maxPage: 0,
    hasMore: false
  });

  protected readonly gridEngineService: GridEngineService = inject(GridEngineService);

  constructor() {
    super();

    effect(() => {
      const gridMeta = this.gridMeta();
      if (gridMeta) {
        this.setColumnDefs(gridMeta);
      }
    });

    effect(() => {
      const gridData = this.gridData();
      if (gridData) {
        this.setData(gridData);
      }
    });
  }

  ngOnInit() {
    this.onRowDataChangeEffect();
    this.gridExportService.onExport$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params: ExportParams) => {
      this.openExportForm(params);
    });
    effect(
      () => {
        const masterViewType = this.masterViewType();
        if (masterViewType?.useStaticMetaData) {
          this.loadStaticMetaData(masterViewType.name);
        }
      },
      { injector: this.injector }
    );
  }

  getSelectionColumnDef(gridMeta: any, userPermissions: any) {
    const isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(
      gridMeta?.masterViewTable?.bluePrint?.buttonColumn?.visibleConditions,
      userPermissions
    );
    if (gridMeta?.masterViewTable?.bluePrint?.selectionColumn && isCallToActionVisible) {
      return {
        pinned: 'left',
        lockPinned: true,
        suppressHeaderMenuButton: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        sortable: true,
        headerClass: 'ag-checkbox__pinned-left',
        cellClassRules: {
          'ag-checkbox__pinned-left': () => true
        },
        headerValueGetter: this.localizedHeader.bind(this)
      } as ColDef;
    }
    return null;
  }

  onRowDataChangeEffect() {
    effect(
      () => {
        const rowData = this.rowData();
        const gridMeta = this.gridMeta();
        const userPermissions = this.userPermissions();
        untracked(() => {
          if (this.gridApi) {
            const gridOptions: any = {
              rowData
            };
            const selColDef = this.getSelectionColumnDef(gridMeta, userPermissions);
            if (selColDef) {
              gridOptions.rowSelection = {
                ...(this.gridOptions?.rowSelection as RowSelectionOptions),
                checkboxes: true,
                headerCheckbox: true
              };
              gridOptions.selectionColumnDef = selColDef;
            }
            this.gridApi.updateGridOptions(gridOptions);
            const timeout = setTimeout(() => {
              this.gridApi.refreshCells({ force: true });
              this.autoSizeAll();
              this.sizeColumnsToFit();
              clearTimeout(timeout);
            }, 300);
          }
        });
      },
      { injector: this.injector }
    );
  }

  onFirstDataRendered(): void {
    const gridData = this.gridData();
    const visibleNodeId = this.visibleNodeId();
    this.setGridSort(SortModelAdapter.toGridSortModel(gridData?.initialSort));
    if (visibleNodeId) {
      this.scrollTo(this.gridApi.getRowNode(visibleNodeId)?.rowIndex);
    }
  }

  onSortChanged(event: SortChangedEvent) {
    this.sortChange.emit(event.api.getColumnState().map(({ colId, sort }) => ({ colId, sort })));
  }

  private setColumnDefs(colDefs: any): void {
    const userPermissions = this.userPermissions();
    let columnDefs = colDefs.masterViewTable.bluePrint.columns.sort(SortUtil.sortByOrder).map((col) => {
      let columnDefinition: ColDef = {
        field: col.id,
        headerName: col.name,
        headerValueGetter: this.localizedHeader.bind(this),
        sortable: col.sortable,
        pinned: col.pinned ? col.pinned : undefined,
        resizable: true,
        width: col.cellWidth ? parseInt(col.cellWidth) : undefined,
        maxWidth: col.maxWidth ? col.maxWidth : undefined,
        suppressHeaderMenuButton: false,
        headerComponent: col.headerType,
        headerComponentParams: {
          enableMenu: false,
          headerIcon: col.headerIcon ?? '',
          headerTooltip: col.headerTooltip ?? ''
        },
        cellRenderer: col.cellType,
        cellRendererParams: {
          eventConfiguration: { type: col.clickEvent?.type ?? '', options: col.clickEvent?.options ?? '' },
          cellOptions: col.cellOptions ? col.cellOptions : col.cellTypeOptions ?? '',
          userPermissions,
          dispatchEvent: (event) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        }
      };

      const enableFilter = get(col, ['filterParams', 'enabled'], false);
      if (enableFilter) {
        const filterDefinition = GridFiltersHelpers.getFilter({
          ...col,
          configuration: {
            cell: {
              type: col.cellType
            },
            filterParams: get(col, ['filterParams'])
          }
        });
        columnDefinition = {
          ...columnDefinition,
          headerComponentParams: {
            ...columnDefinition.headerComponentParams,
            enableMenu: true
          },
          ...filterDefinition,
          filterParams: {
            ...filterDefinition.filterParams,
            ...get(col, ['filterParams'])
          },
          suppressFiltersToolPanel: true,
          menuTabs: ['filterMenuTab' /* , 'generalMenuTab', 'columnsMenuTab' */]
        };
      }

      if (col.valueGetter) {
        if (col.valueGetter instanceof Function) {
          columnDefinition.valueGetter = col.valueGetter.bind(this);
        } else {
          columnDefinition.valueGetter = (params: ValueGetterParams) => get(params.data, col.valueGetter);
        }
      }

      if (col.cellType === I4BCellType.RICH_VARIABLE) {
        columnDefinition.cellStyle = (params: CellClassParams) => new RichVariableColumn().cellStyle(params);
      }
      if (col.cellType === I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL) {
        columnDefinition.valueGetter = (params: ValueGetterParams) => new I4BThresholdColumn().valueGetter(params);
      }

      return columnDefinition;
    });

    const isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(
      colDefs.masterViewTable.bluePrint.buttonColumn?.visibleConditions,
      userPermissions
    );
    const buttonColumn = colDefs.masterViewTable.bluePrint.buttonColumn;
    if (buttonColumn && isCallToActionVisible) {
      columnDefs = [
        ...columnDefs,
        {
          field: buttonColumn.id,
          headerName: buttonColumn.name,
          headerValueGetter: this.localizedHeader.bind(this),
          sortable: buttonColumn.sortable,
          suppressHeaderMenuButton: true,
          width: 40,
          maxWidth: 40,
          pinned: 'right',
          lockPinned: true,
          headerComponent: HeaderType.CALL_TO_ACTION,
          headerComponentParams: {
            bulkActions: colDefs.masterViewTable.bluePrint.buttonColumn.bulkActions,
            visibleConditions: colDefs.masterViewTable.bluePrint.buttonColumn.visibleConditions,
            userPermissions,
            dispatchEvent: (event) => {
              this.dispatchMasterViewEngineEvent.emit(event);
            }
          },
          cellRenderer: I4BCellType.CALL_TO_ACTION,
          cellRendererParams: {
            actions: colDefs.masterViewTable.bluePrint.buttonColumn.singleActions,
            userPermissions,
            dispatchEvent: (event) => {
              this.dispatchMasterViewEngineEvent.emit(event);
            }
          },
          valueGetter: (params) => JSON.stringify(params.data)
        }
      ];
    }
    this.columnDefs.set([...columnDefs]);
  }

  private setGridSort(initialSort: gridSortModel[]): void {
    if (this.gridApi && initialSort) {
      this.gridApi.applyColumnState({
        state: initialSort.map((e) => ({
          colId: e.colId,
          sort: e.sort
        })) as ColumnState[]
      });
      this.gridApi.refreshHeader();
    }
  }

  private openExportForm(params: ExportParams): void {
    const pagination = this.pagination();
    const rowData = this.rowData();
    const gridMeta = this.gridMeta();
    this.gridExportService.setPagination(pagination);
    this.gridExportService.setRowData([...rowData]);
    this.gridExportService.setGridMeta(gridMeta);
    this.gridExportService.setParams(params);
    this.dialog.open(GridExportDialogComponent, {
      width: '500px',
      disableClose: true,
      data: { totalElements: params.totalElements }
    });
  }

  private setRowData(data): void {
    this.rowData.set([...data]);
  }

  private setPagination(paginationInfo): void {
    this.pagination.update((pagination) => ({
      ...pagination,
      total: paginationInfo.total,
      currentPage: paginationInfo.currentPage,
      hasMore: paginationInfo.hasMore,
      maxPage: paginationInfo.maxPage,
      limit: paginationInfo.limit
    }));
  }

  private localizedHeader(parameters: ICellRendererParams): string {
    const headerIdentifier = parameters.colDef.headerName;
    return headerIdentifier ? this.translateService.instant(headerIdentifier) : '';
  }

  private loadStaticMetaData(masterViewName: string): void {
    this.gridEngineService
      .loadStaticMetaData(masterViewName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((masterView) => {
        this.gridMeta.set(masterView);
      });
  }

  private setData(platformResponse: PlatformResponse) {
    this.setRowData(platformResponse.data);
    this.setPagination(platformResponse);
  }
}
