import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { ArrayUtils, DuplicateUtils, StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceVariable as DaliaDeviceVariable,
  DeviceVariableState,
  ModbusListItem,
  ModbusTable
} from '@iot-platform/models/dalia';
import { DeviceVariable as I4bDeviceVariable } from '@iot-platform/models/i4b';
import { get, uniq, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DeviceConfigurationHelpers } from './device-configuration.helpers';
import { DeviceVariableMapper } from './device-variable.mapper';

export class DeviceVariableHelpers {
  // uid is a generated attribute used in variables comparators
  // sometimes for some dalia variables we find variables with no names
  // so in this case we generate a uid so as not to have conflict with unnamed iot4bos variables
  static readonly DALIA_DEVICE_VAR_UID_KEY = 'uid';

  static MAX_ALLOWED_VARIABLE_INDEX = 50;

  static readonly NAME_MAX_LENGTH = 24;
  static readonly NAME_MIN_LENGTH = 1;
  static readonly COMMENT_MAX_LENGTH = 32;

  static mapper = new DeviceVariableMapper();

  // Return all variable names from device configurations
  // Exclude variables to be deleted
  static getAllVariableNames(
    configuration: DeviceConfiguration,
    skipRemovedVariables: boolean
  ): {
    key: string;
    index: number;
    value: string;
  }[] {
    const getNamesFrom = (targetConfiguration: string) => {
      const config = get(configuration, [targetConfiguration], {});
      const nameValueGetter = (key: string) => {
        const path = [`${key}.${this.mapper.ATTRIBUTE_MAPPER.name}`];
        if (targetConfiguration === 'current') {
          path.push('v');
        }
        return path;
      };
      return DeviceVariableHelpers.getPropertiesFromConfig(config, nameValueGetter);
    };
    const currentNames: { key: string; index: number; value: string }[] = getNamesFrom('current');
    const pendingNames: { key: string; index: number; value: string }[] = getNamesFrom('pending');
    const targetNames: { key: string; index: number; value: string }[] = getNamesFrom('target');
    // Remove variables to be deleted
    return uniqBy([...currentNames, ...pendingNames, ...targetNames], 'key').filter(({ key }) => {
      if (skipRemovedVariables) {
        // Check if the variable will be deleted
        // Kind === 0
        const kind = get(
          configuration,
          ['pending', `${key}.${this.mapper.ATTRIBUTE_MAPPER.kind}`],
          get(configuration, ['target', `${key}.${this.mapper.ATTRIBUTE_MAPPER.kind}`])
        );
        return kind !== 0;
      }
      return true;
    });
  }

  // Generate processed dalia variable list
  static readonly getVariables = (device: DeviceDetails, modbusTable: ModbusTable): DaliaDeviceVariable[] => {
    const currentKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(device, ['configuration', 'current'], {}));
    const pendingKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(device, ['configuration', 'pending'], {}));
    const targetKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(device, ['configuration', 'target'], {}));

    // Created new variables does not exist in current configuration, they exist in pending configuration instead
    const createdNewVariableKeys = pendingKeys.filter((k) => !currentKeys.includes(k));
    // Updated variables exist in both of current and pending configurations
    const updatedVariableKeys = currentKeys.filter((k) => pendingKeys.includes(k));
    // Unmodified variables should not exist in created and updated lists
    const currentVariableKeys = currentKeys.filter((k) => !createdNewVariableKeys.includes(k) && !updatedVariableKeys.includes(k));

    const currentVariables = DeviceVariableHelpers.getVariablesFromConfiguration(
      currentVariableKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const updatedVariables = DeviceVariableHelpers.getVariablesFromConfiguration(
      updatedVariableKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const createdVariables = DeviceVariableHelpers.getVariablesFromConfiguration(
      createdNewVariableKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['pending', key], // should retrieve properties from pending configuration for created variables
      true
    );
    const items = [...currentVariables, ...updatedVariables, ...createdVariables].sort((a, b) => a.index - b.index);
    return items.slice(0, DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX);
  };

  // Merge I4B and dalia variables
  static readonly mergeVariables = (variables: I4bDeviceVariable[], daliaVariableList: DaliaDeviceVariable[]): DaliaDeviceVariable[] => {
    const i4bVariableList: DaliaDeviceVariable[] = variables.map((i4bDeviceVariable: I4bDeviceVariable) => {
      // Search variable by uid
      let daliaDeviceVariable = daliaVariableList.find((v) => i4bDeviceVariable.name === v[this.DALIA_DEVICE_VAR_UID_KEY]);

      // For variables that have been removed, they will be duplicated in current and pending configurations
      // Cause technically, to remove a variable configuration
      // We just keep the current configuration and we make a copy of it in pending configuration with empty values
      // So in this case, we should search variable by name and kind 0 in current config to get its status (pending or target)
      // Then we set it as not configurable to prevent configure and delete actions on it
      if (!daliaDeviceVariable) {
        const found = daliaVariableList.find((v) => {
          const key = DeviceVariableHelpers.getVariableIndexKey(v);
          return (
            get(v, ['kind', 'value']) === 0 &&
            i4bDeviceVariable.name === get(v, ['device', 'configuration', 'current', `${key}.${this.mapper.ATTRIBUTE_MAPPER.name}`, 'v'])
          );
        });
        if (found)
          daliaDeviceVariable = {
            ...found,
            canConfigure: false
          };
      }

      // For i4b variables that does not have a group or their group is "Configured"
      // These variables should be marked as deleted
      let status = !i4bDeviceVariable.group || i4bDeviceVariable.group === 'Configured' ? DeviceConfigurationStatus.DELETED : DeviceConfigurationStatus.CURRENT;
      if (daliaDeviceVariable) {
        status = daliaDeviceVariable.status;
      }

      return {
        ...i4bDeviceVariable,
        ...daliaDeviceVariable,
        id: i4bDeviceVariable.id,
        name: i4bDeviceVariable.name,
        unit: i4bDeviceVariable.unit,
        linked: !!i4bDeviceVariable.linked,
        status
      };
    });
    // Return a diff array and remove variables with kind == 0
    const diffVariableList: DaliaDeviceVariable[] = ArrayUtils.diffByKeys(daliaVariableList, i4bVariableList, this.DALIA_DEVICE_VAR_UID_KEY, 'name').filter(
      (v) => get(v, ['kind', 'value']) !== 0
    );
    return [...i4bVariableList, ...diffVariableList];
  };

  static readonly getPropertiesFromConfig = (
    configuration: {
      [key: string]: unknown;
    },
    valueGetter: (key: string) => string[]
  ): {
    key: string;
    index: number;
    value: string;
  }[] => {
    const keys = DeviceVariableHelpers.getVariableKeysFromConfig(configuration);
    return keys.map((key) => ({
      key,
      index: DeviceConfigurationHelpers.parseIndex(key),
      value: get(configuration, valueGetter(key))
    }));
  };

  static readonly getVariableKeysFromConfig = (configuration: { [key: string]: unknown }): string[] => {
    const keys = Object.keys(configuration)
      .filter((key: string) => key.match(/(^Var\d+(\.Variable_name.)*)./gi))
      .map((key) => key.split('.')[0]);
    return uniq(keys);
  };

  static readonly getAvailableNextIndex = (configuration: DeviceConfiguration): number => {
    const indexes = DeviceVariableHelpers.getAllVariableNames(configuration, false).map((item) => item.index);
    return DeviceConfigurationHelpers.getAvailableNextIndex(indexes, DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX);
  };

  static readonly getAvailableIndexes = (configuration: DeviceConfiguration, currentIndex?: number): ModbusListItem[] => {
    const indexes = DeviceVariableHelpers.getAllVariableNames(configuration, false).map((item) => item.index);
    return DeviceConfigurationHelpers.getAvailableIndexes(indexes, DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX, currentIndex);
  };

  static readonly getVariablesFromConfiguration = (
    keys: string[],
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DaliaDeviceVariable[] =>
    uniq(keys).reduce((acc, key) => {
      const variable = this.getVariableInstance(key, device, modbusTable, pendingKeys, targetKeys, valueGetter, isCreated);
      return [...acc, variable];
    }, []);

  static getVariableState = (variable: DaliaDeviceVariable, modbusTable: ModbusTable): DeviceVariableState =>
    this.mapper.getConfigurationState(variable, modbusTable);

  // Return variable prefix key by index
  static readonly getVariableIndexKey = (variable: DaliaDeviceVariable): string => `Var${StringUtils.padWith(variable?.index, 2, '0')}`;

  // Return a new configuration copy without properties that match variable index
  static readonly removePropertiesFrom = (
    variable: DaliaDeviceVariable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(variable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get variable key
    const key = DeviceVariableHelpers.getVariableIndexKey(variable);
    return DeviceConfigurationHelpers.reduceConfiguration(configuration, key, targetConfiguration, false);
  };

  // Return all configuration properties that match variable index
  static readonly getPropertiesFrom = (
    variable: DaliaDeviceVariable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(variable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get variable key
    const key = DeviceVariableHelpers.getVariableIndexKey(variable);
    return DeviceConfigurationHelpers.reduceConfiguration(configuration, key, targetConfiguration, true);
  };

  // Variable that does not have an index or their kind is 0 are not configurable
  static readonly canConfigureVariable = (variable: DaliaDeviceVariable) =>
    variable.index !== null &&
    variable.index !== undefined &&
    variable.kind?.value !== 0 &&
    !Object.keys(get(variable.device, ['configuration', 'target'], {})).length;

  static readonly canDuplicateVariable = (configuration: DeviceConfiguration) => {
    const index = DeviceVariableHelpers.getAvailableNextIndex(configuration);
    return index <= DeviceVariableHelpers.MAX_ALLOWED_VARIABLE_INDEX && !Object.keys(get(configuration, ['target'], {})).length;
  };

  static readonly deleteConfigurationFrom = (variable: DaliaDeviceVariable, targetConfiguration: 'current' | 'pending' | 'target') => {
    const configuration = get(variable, ['device', 'configuration'], {});
    const currentKeys: string[] = DeviceVariableHelpers.getVariableKeysFromConfig(get(configuration, 'current', {}));
    const indexKey = DeviceVariableHelpers.getVariableIndexKey(variable);
    if (uniq(currentKeys).includes(indexKey)) {
      // Set kind to 0
      return {
        ...get(configuration, [targetConfiguration], {}),
        [`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.kind}`]: 0
      };
    }
    return DeviceVariableHelpers.removePropertiesFrom(variable, targetConfiguration);
  };

  static readonly duplicateConfigurationFrom = (variable: DaliaDeviceVariable) => {
    const deviceConfiguration = DeviceConfigurationHelpers.mergeConfiguration(get(variable, ['device', 'configuration']), variable.status);
    const variablesNames = this.getAllVariableNames(deviceConfiguration, false).map((item) => item.value);
    const variableKey = DeviceVariableHelpers.getVariableIndexKey(variable);
    const index = DeviceVariableHelpers.getAvailableNextIndex(deviceConfiguration);
    const newVariableKey = DeviceVariableHelpers.getVariableIndexKey({ index } as DaliaDeviceVariable);
    const configuration = DeviceConfigurationHelpers.reduceConfiguration(deviceConfiguration, variableKey, 'current', true);
    return Object.entries(configuration).reduce((acc, [key]) => {
      if (key.indexOf(`${variableKey}.`) !== -1) {
        const k = key.replace(variableKey, newVariableKey);
        let value = get(configuration, [key]) as string;
        if (key === `${variableKey}.${this.mapper.ATTRIBUTE_MAPPER.name}`) {
          value = DuplicateUtils.duplicateValueString(value, variablesNames, 'DUPLICATED', '_', this.NAME_MAX_LENGTH);
        }
        acc[k] = value;
      }
      return { ...acc };
    }, {});
  };

  static readonly cleanUpConfiguration = (
    indexKey: string,
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target',
    skipDiffValues?: boolean
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    // Check for alarm rule
    const alarmKey = `${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.alarm}`;
    const alarm = get(configuration, [alarmKey], 0) as number;
    const alarmOfTypeDifferent = ModbusTableHelpers.alarmOfTypeDifferent(alarm);
    const alarmOfTypeInsideOrOutsideInterval = ModbusTableHelpers.alarmOfTypeInsideOrOutsideInterval(alarm);

    // Check for variable to copy rule
    const kind = get(configuration, [`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.kind}`]) as number;
    const modbusFunction = get(configuration, [`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.modbusFunction}`]) as number;
    const hasVariableToCopy = ModbusTableHelpers.hasVariableToCopy(kind, modbusFunction);

    // Check for modbus bitmask rule
    const modbusType = get(configuration, [`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.modbusType}`]) as number;
    const hasBitmask = ModbusTableHelpers.hasBitmask(modbusType);

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      const canRemoveAlarmField = alarm === 0 && key !== alarmKey && key.indexOf(`${indexKey}.Alarm.`) !== -1;
      const canRemoveUpperThresholdField =
        (alarmOfTypeDifferent &&
          (key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.upperThreshold}`) !== -1 ||
            key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.upperThresholdSeverity}`) !== -1 ||
            key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.upperThresholdClass}`) !== -1)) ||
        (alarmOfTypeInsideOrOutsideInterval &&
          (key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.upperThresholdSeverity}`) !== -1 ||
            key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.upperThresholdClass}`) !== -1));
      const canRemoveVariableToCopyField = !hasVariableToCopy && key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.modbusVariableToCopy}`) !== -1;
      const canRemoveModbusBitmaskField = !hasBitmask && key.indexOf(`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.modbusBitmask}`) !== -1;
      if (
        !(
          (!skipDiffValues && config[key] === currentValue) ||
          canRemoveAlarmField ||
          canRemoveVariableToCopyField ||
          canRemoveModbusBitmaskField ||
          canRemoveUpperThresholdField
        )
      ) {
        acc[key] = config[key];
      }
      return { ...acc };
    }, {});
  };

  private static getVariableInstance(
    key: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ) {
    const status = DeviceConfigurationHelpers.getStatusByKey(key, device?.configuration, pendingKeys, targetKeys);
    const index = DeviceConfigurationHelpers.parseIndex(key);

    const uid = uuidv4();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let variable: any = {
      id: uid,
      index,
      device,
      status,
      isCreated,
      linked: false
    };
    const mapping = this.mapper.getMappingModel({
      data: variable,
      modbusTable,
      valueGetter,
      configuration: get(variable, ['device', 'configuration'])
    });
    variable = {
      ...variable,
      ...mapping
    };

    // This is used as business rule to enable/disable variable configuration
    const canConfigure = this.canConfigureVariable(variable);
    const canDuplicate = this.canDuplicateVariable(get(device, ['configuration'], {}) as DeviceConfiguration);
    const hasCleanConfiguration = this.mapper.hasCleanConfiguration(variable, modbusTable);

    return {
      ...variable,
      // In case the variable name is empty we generate a uid to be used later in comparators
      uid: variable.name && !!variable.name.length ? variable.name : uid,
      canConfigure,
      canDuplicate,
      hasCleanConfiguration
    };
  }
}
