import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { GridManagerUserPopupComponent, GridsDbActions } from '@iot-platform/grid-engine';
import { Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class GridCtaService {
  readonly dialog: MatDialog = inject(MatDialog);
  protected readonly store: Store = inject(Store);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  openGridSettingsDialog(grid: I4BGrid<I4BGridOptions, I4BGridData>, filters: Filter[]): void {
    this.dialog
      .open(GridManagerUserPopupComponent, {
        width: '1350px',
        maxWidth: '1350px',
        disableClose: true,
        data: { grid, data: null }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value?.action === 'DELETE') {
          this.store.dispatch(
            GridsDbActions.removeGrid({
              toRemove: {
                ...value.grid,
                gridOptions: { ...value.grid.gridOptions, filters }
              }
            })
          );
        }
        if (value?.action === 'UPDATE') {
          this.store.dispatch(
            GridsDbActions.updateGrid({
              toUpdate: {
                ...value.grid,
                gridOptions: { ...value.grid.gridOptions, filters }
              }
            })
          );
        }
        if (value?.action === 'ADD') {
          this.store.dispatch(
            GridsDbActions.addGrid({
              toAdd: {
                ...value.grid,
                gridOptions: { ...value.grid.gridOptions, filters }
              }
            })
          );
        }
      });
  }
}
