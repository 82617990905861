import { GoogleAnalyticsTrackingTag, TrackingData, TrackingProviders, TrackingSettings } from '@iot-platform/core';
import { TrackingEventCategories, TrackingEventIds, TrackingEventLabels, TrackingEventNames } from '@iot-platform/models/dalia';
import { GoogleAnalyticsMapperService } from '../services/google-analytics-mapper.service';

export const trackingSettings: TrackingSettings = {
  tags: {
    /**
     Devices master view
     */
    [TrackingEventIds.NAVIGATE_TO_DEVICES_MASTER_VIEW]: /* 👈 track from route path */ {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICES_MASTER_VIEW
      })
    },
    [TrackingEventIds.OPEN_DEVICES_FILTER_ENGINE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: data.filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.REFRESH_DEVICES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.EXPORTS_DEVICES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.EXPORT_DATA
      })
    },
    [TrackingEventIds.APPLY_DEVICES_FILTERS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.FILTER_ACTION,
        label: TrackingEventLabels.APPLY_FILTERS
      })
    },
    [TrackingEventIds.NAVIGATE_TO_DEVICE_OVERVIEW_PAGE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICE_OVERVIEW_PAGE
      })
    },
    [TrackingEventIds.SEND_DEVICE_SHELL_COMMAND]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.SEND_COMMAND
      })
    },
    /**
     Devices overview view
     */
    [TrackingEventIds.REFRESH_DEVICE_OVERVIEW_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.NAVIGATE_TO_DEVICES_MASTER_VIEW_FROM_DEVICE_OVERVIEW]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICES_MASTER_VIEW
      })
    },
    [TrackingEventIds.OPEN_DEVICE_AFFILIATE_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_AFFILIATE_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_ENDPOINT_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_ENDPOINT_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_INFO
      })
    },
    [TrackingEventIds.OPEN_DEVICE_NAME_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_NAME_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_STARTING_DATE_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_STARTING_DATE_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_AUDIT_TRAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_HISTORY
      })
    },
    [TrackingEventIds.SEND_DEVICE_OVERVIEW_COMMAND]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.SEND_COMMAND
      })
    },
    [TrackingEventIds.DEVICE_DETAILS_TAB_CHANGE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_CHANGE,
        label: data.tabLabel as string
      })
    },
    [TrackingEventIds.PUBLISH_DEVICE_GLOBAL_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.PUBLISH_DEVICE_GLOBAL_CONFIGURATION
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_GLOBAL_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.CANCEL_DEVICE_GLOBAL_CONFIGURATION
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_VARIABLE_PENDING_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.CANCEL_PENDING_CONFIGURATION
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_VARIABLE_DELETE_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.CANCEL_DELETE_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_VARIABLE_DETAILS]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_VARIABLE_DETAILS
      })
    },
    [TrackingEventIds.OPEN_DEVICE_VARIABLE_CHART]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_CHART
      })
    },
    [TrackingEventIds.OPEN_DEVICE_VARIABLE_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_VARIABLE_CREATE_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_VARIABLE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_VARIABLE_EDIT_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.EDIT_DEVICE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.EDIT_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.DELETE_DEVICE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.BULK_DELETE_DEVICE_VARIABLES]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.BULK_DELETE_DEVICE_VARIABLES
      })
    },
    [TrackingEventIds.OPEN_DEVICE_OVERVIEW_MODBUS_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_MODBUS_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_OVERVIEW_MODBUS_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_MODBUS_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_OVERVIEW_ALARM_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_ALARM_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_OVERVIEW_AUTONOMY_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_AUTONOMY_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_OVERVIEW_ALARM_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_ALARM_CONFIGURATION
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_OVERVIEW_AUTONOMY_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_AUTONOMY_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_OVERVIEW_ENERGY_MODE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_ENERGY_MODE_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_OVERVIEW_ENERGY_MODE_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_ENERGY_MODE_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_OVERVIEW_COMMUNICATION_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_COMMUNICATION_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_OVERVIEW_COMMUNICATION_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_COMMUNICATION_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_ICCID_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_ICCID_DETAILS
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_IMPORT_FROM_DEVICE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TEMPLATE_IMPORT_FROM_DEVICE_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_IMPORT_FROM_TEMPLATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TEMPLATE_IMPORT_FROM_TEMPLATE_FORM
      })
    },
    [TrackingEventIds.DEVICE_CONFIGURATION_APPLY_TEMPLATE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.DEVICE_CONFIGURATION_APPLY_TEMPLATE
      })
    },
    [TrackingEventIds.DEVICE_CONFIGURATION_COPY_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.DEVICE_CONFIGURATION_COPY_CONFIGURATION
      })
    },
    [TrackingEventIds.SAVE_DEVICE_ROLLING_PERIOD]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.SAVE_DEVICE_ROLLING_PERIOD
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TANK_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TANK_CREATE_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_TANK
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TANK_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TANK_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_TANK
      })
    },
    [TrackingEventIds.DELETE_DEVICE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_TANK
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_TANK
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_TANK_PENDING_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.CANCEL_PENDING_CONFIGURATION
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_TANK_DELETE_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.CANCEL_DELETE_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_MODBUS_REQUEST_TABLE_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_MODBUS_REQUEST_TABLE_CREATE_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.OPEN_DEVICE_MODBUS_REQUEST_TABLE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_MODBUS_REQUEST_TABLE_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.DELETE_DEVICE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_MODBUS_REQUEST_TABLE_PENDING_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.CANCEL_PENDING_CONFIGURATION
      })
    },
    [TrackingEventIds.CANCEL_DEVICE_MODBUS_REQUEST_TABLE_DELETE_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICES_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.CANCEL_DELETE_CONFIGURATION
      })
    },
    /**
     Firmwares master view
     */
    [TrackingEventIds.NAVIGATE_TO_FIRMWARES_MASTER_VIEW]: /* 👈 track from route path */ {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.FIRMWARES_SHELL,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_FIRMWARES_MASTER_VIEW
      })
    },
    [TrackingEventIds.OPEN_FIRMWARES_FILTER_ENGINE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.FIRMWARES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: data.filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.REFRESH_FIRMWARES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.FIRMWARES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.EXPORTS_FIRMWARES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.FIRMWARES_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.EXPORT_DATA
      })
    },
    [TrackingEventIds.APPLY_FIRMWARES_FILTERS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.FIRMWARES_SHELL,
        category: TrackingEventCategories.FILTER_ACTION,
        label: TrackingEventLabels.APPLY_FILTERS
      })
    },
    [TrackingEventIds.OPEN_FIRMWARE_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.FIRMWARES_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_FIRMWARE_DETAILS
      })
    },
    /**
     Sensors master view
     */
    [TrackingEventIds.NAVIGATE_TO_SENSORS_MASTER_VIEW]: /* 👈 track from route path */ {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.SENSORS_SHELL,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_SENSORS_MASTER_VIEW
      })
    },
    [TrackingEventIds.OPEN_SENSORS_FILTER_ENGINE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.SENSORS_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: data.filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.REFRESH_SENSORS_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.SENSORS_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.EXPORTS_SENSORS_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.SENSORS_SHELL,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.EXPORT_DATA
      })
    },
    [TrackingEventIds.APPLY_SENSORS_FILTERS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.SENSORS_SHELL,
        category: TrackingEventCategories.FILTER_ACTION,
        label: TrackingEventLabels.APPLY_FILTERS
      })
    },
    [TrackingEventIds.OPEN_SENSOR_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.SENSORS_SHELL,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_SENSOR_DETAILS
      })
    },
    /**
     Device templates master view
     */
    [TrackingEventIds.NAVIGATE_TO_DEVICE_TEMPLATES_MASTER_VIEW]: /* 👈 track from route path */ {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICE_TEMPLATES_MASTER_VIEW
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATES_FILTER_ENGINE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: data.filterEngineOpened ? TrackingEventLabels.OPEN_FILTER_ENGINE : TrackingEventLabels.CLOSE_FILTER_ENGINE
      })
    },
    [TrackingEventIds.REFRESH_DEVICE_TEMPLATES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.REFRESH_DATA
      })
    },
    [TrackingEventIds.EXPORTS_DEVICE_TEMPLATES_DATA]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.EXPORT_DATA
      })
    },
    [TrackingEventIds.APPLY_DEVICE_TEMPLATES_FILTERS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.FILTER_ACTION,
        label: TrackingEventLabels.APPLY_FILTERS
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_DETAILS]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TEMPLATE_DETAILS
      })
    },
    [TrackingEventIds.DELETE_DEVICE_TEMPLATE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_TEMPLATE
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TEMPLATE_CREATE_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_TEMPLATE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.TOOLBAR_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_TEMPLATE
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_TEMPLATE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_TEMPLATE
      })
    },
    [TrackingEventIds.NAVIGATE_TO_DEVICE_TEMPLATE_OVERVIEW_PAGE]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATES,
        category: TrackingEventCategories.NAVIGATION,
        label: TrackingEventLabels.NAVIGATE_TO_DEVICE_TEMPLATE_OVERVIEW_PAGE
      })
    },
    /**
     Device template overview view
     */
    [TrackingEventIds.DEVICE_TEMPLATE_DETAILS_TAB_CHANGE]: {
      getGoogleTag: (data: TrackingData): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_CHANGE,
        label: data.tabLabel as string
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_OVERVIEW_MODBUS_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_MODBUS_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_OVERVIEW_MODBUS_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_MODBUS_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_OVERVIEW_ALARM_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_ALARM_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_OVERVIEW_AUTONOMY_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_AUTONOMY_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_OVERVIEW_ALARM_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_ALARM_CONFIGURATION
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_OVERVIEW_AUTONOMY_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_AUTONOMY_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_OVERVIEW_ENERGY_MODE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_ENERGY_MODE_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_OVERVIEW_ENERGY_MODE_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_ENERGY_MODE_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_OVERVIEW_COMMUNICATION_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_COMMUNICATION_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_OVERVIEW_COMMUNICATION_CONFIGURATION]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_COMMUNICATION_CONFIGURATION
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_VARIABLE_DETAILS]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.GRID_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_VARIABLE_DETAILS
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_VARIABLE_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_VARIABLE_CREATE_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_TEMPLATE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_VARIABLE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_VARIABLE_EDIT_FORM
      })
    },
    [TrackingEventIds.EDIT_DEVICE_TEMPLATE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.EDIT_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.DELETE_DEVICE_TEMPLATE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_TEMPLATE_VARIABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_VARIABLES_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_VARIABLE
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_NAME_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_NAME_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_DEVICE_INFO_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION
      })
    },
    [TrackingEventIds.SAVE_DEVICE_TEMPLATE_ROLLING_PERIOD]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.SAVE_DEVICE_ROLLING_PERIOD
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_TANK_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TANK_CREATE_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_TANK_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TANK_EDIT_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_TEMPLATE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_TANK
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_TANK
      })
    },
    [TrackingEventIds.DELETE_DEVICE_TEMPLATE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_TANK
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_TEMPLATE_TANK]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_TANKS_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_TANK
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_TEMPLATE_EDIT_FORM
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_TEMPLATE
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_STARTING_DATE_EDIT_FORM]: {
      getGoogleTag: (/* data: TrackingData */): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_INFOS_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_STARTING_DATE_EDIT_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE_CREATE_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_MODBUS_REQUEST_TABLE_CREATE_FORM
      })
    },
    [TrackingEventIds.OPEN_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE_EDIT_FORM]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.OPEN_DEVICE_MODBUS_REQUEST_TABLE_EDIT_FORM
      })
    },
    [TrackingEventIds.CREATE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.CREATE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.UPDATE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.UPDATE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.DELETE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.DELETE_DEVICE_MODBUS_REQUEST_TABLE
      })
    },
    [TrackingEventIds.DUPLICATE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE]: {
      getGoogleTag: (): GoogleAnalyticsTrackingTag => ({
        eventName: TrackingEventNames.DEVICE_TEMPLATE_OVERVIEW,
        category: TrackingEventCategories.TAB_MODBUS_REQUEST_TABLE_ACTION,
        label: TrackingEventLabels.DUPLICATE_DEVICE_MODBUS_REQUEST_TABLE
      })
    }
  },
  providers: [
    { provider: TrackingProviders.GoogleAnalytics, mapper: GoogleAnalyticsMapperService }
    // The Hotjar tracking mapper is not used yet
    // { provider: TrackingProviders.HotjarAnalytics, mapper: HotjarAnalyticsMapperService }
  ]
};
