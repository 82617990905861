import { StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceTank,
  DeviceTankState,
  ModbusListItem,
  ModbusTable
} from '@iot-platform/models/dalia';
import { get, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DeviceConfigurationHelpers } from './device-configuration.helpers';
import { DeviceTankMapper } from './device-tank.mapper';

export class DeviceTankHelpers {
  static MAX_ALLOWED_TANK_INDEX = 10;

  /*
    *) LstTankType
    Vertical (1)
    Horizontal (2)
    Vertical for autonomy (3)
    Horizontal for autonomy (4)
    Vertical backup (5)
    Horizontal backup (6)
  */
  static IMAGE_URL_MAPPER = {
    // LstGasType: N2
    1: {
      1: 'bulk_LIN.png',
      2: 'bulk_HorizontalLIN.png',
      3: 'bulk_LIN.png',
      4: 'bulk_HorizontalLIN.png',
      5: 'bulk_LIN.png',
      6: 'bulk_HorizontalLIN.png'
    },
    // LstGasType: CO2
    2: {
      1: 'bulk_LCO2.png',
      2: 'bulk_HorizontalLCO2.png',
      3: 'bulk_LCO2.png',
      4: 'bulk_HorizontalLCO2.png',
      5: 'bulk_LCO2.png',
      6: 'bulk_HorizontalLCO2.png'
    },
    // LstGasType: O2
    3: {
      1: 'bulk_LOX.png',
      2: 'bulk_HorizontalLOX.png',
      3: 'bulk_LOX.png',
      4: 'bulk_HorizontalLOX.png',
      5: 'bulk_LOX.png',
      6: 'bulk_HorizontalLOX.png'
    },
    // LstGasType: Ar
    4: {
      1: 'bulk_LAR.png',
      2: 'bulk_HorizontalLAR.png',
      3: 'bulk_LAR.png',
      4: 'bulk_HorizontalLAR.png',
      5: 'bulk_LAR.png',
      6: 'bulk_HorizontalLAR.png'
    },
    // LstGasType: He
    5: {
      1: 'bulk_general.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_general.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_general.png',
      6: 'bulk_Horizontal.png'
    },
    // LstGasType: H2
    6: {
      1: 'bulk_LH2.png',
      2: 'bulk_HorizontalLH2.png',
      3: 'bulk_LH2.png',
      4: 'bulk_HorizontalLH2.png',
      5: 'bulk_LH2.png',
      6: 'bulk_HorizontalLH2.png'
    },
    // LstGasType: N2O
    7: {
      1: 'bulk_LN20.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_LN20.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_LN20.png',
      6: 'bulk_Horizontal.png'
    },
    // LstGasType: C2H4
    8: {
      1: 'bulk_general.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_general.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_general.png',
      6: 'bulk_Horizontal.png'
    },
    // LstGasType: C3H6
    9: {
      1: 'bulk_LC3H6.png',
      2: 'bulk_Horizontal.png',
      3: 'bulk_LC3H6.png',
      4: 'bulk_Horizontal.png',
      5: 'bulk_LC3H6.png',
      6: 'bulk_Horizontal.png'
    }
  };

  static mapper = new DeviceTankMapper();

  // Return all tanks from device configurations
  // Exclude deleted tanks
  static getAllTankIndexes(
    configuration: DeviceConfiguration,
    skipRemovedTanks: boolean
  ): {
    key: string;
    index: number;
  }[] {
    const currentKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'current', {}));
    const pendingKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'pending', {}));
    const targetKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'target', {}));

    // Remove tanks to be deleted
    return uniq([...currentKeys, ...pendingKeys, ...targetKeys])
      .filter((key) => {
        // Check if the tank will be deleted
        // type === 0
        const type = get(
          configuration,
          ['pending', `${key}.${this.mapper.ATTRIBUTE_MAPPER.type}`],
          get(
            configuration,
            ['target', `${key}.${this.mapper.ATTRIBUTE_MAPPER.type}`],
            get(configuration, ['current', `${key}.${this.mapper.ATTRIBUTE_MAPPER.type}`])
          )
        );
        if (skipRemovedTanks) {
          return type !== 0;
        }
        return true;
      })
      .map((key: string) => ({ key, index: DeviceConfigurationHelpers.parseIndex(key) }));
  }

  // Generate processed dalia tank list
  static getTanks = (device: DeviceDetails, modbusTable: ModbusTable): DeviceTank[] => {
    const currentKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(device, ['configuration', 'current'], {}));
    const pendingKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(device, ['configuration', 'pending'], {}));
    const targetKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(device, ['configuration', 'target'], {}));

    // Created new tanks does not exist in current configuration, they exist in pending configuration instead
    const createdNewTankKeys = pendingKeys.filter((k) => !currentKeys.includes(k));
    // Updated tanks exist in both of current and pending configurations
    const updatedTankKeys = currentKeys.filter((k) => pendingKeys.includes(k));
    // Unmodified tanks should not exist in created and updated lists
    const currentTankKeys = currentKeys.filter((k) => !createdNewTankKeys.includes(k) && !updatedTankKeys.includes(k));

    const currentTanks = DeviceTankHelpers.getTanksFromConfiguration(
      currentTankKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const updatedTanks = DeviceTankHelpers.getTanksFromConfiguration(
      updatedTankKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const createdTanks = DeviceTankHelpers.getTanksFromConfiguration(
      createdNewTankKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['pending', key], // should retrieve properties from pending configuration for created tanks
      true
    );

    const items = [...currentTanks, ...updatedTanks, ...createdTanks]
      // Should filter on tank.status !== DeviceConfigurationStatus.CURRENT to keep removed items in the list
      .filter((tank) => tank.status !== DeviceConfigurationStatus.CURRENT || tank?.type?.value !== 0)
      .sort((a, b) => a.index - b.index);
    return items.slice(0, DeviceTankHelpers.MAX_ALLOWED_TANK_INDEX);
  };

  static getAvailableNextIndex = (configuration: DeviceConfiguration): number => {
    const indexes = DeviceTankHelpers.getAllTankIndexes(configuration, false).map((item) => item.index);
    return DeviceConfigurationHelpers.getAvailableNextIndex(indexes, DeviceTankHelpers.MAX_ALLOWED_TANK_INDEX);
  };

  static getAvailableIndexes = (configuration: DeviceConfiguration, currentIndex?: number): ModbusListItem[] => {
    const indexes = DeviceTankHelpers.getAllTankIndexes(configuration, false).map((item) => item.index);
    return DeviceConfigurationHelpers.getAvailableIndexes(indexes, DeviceTankHelpers.MAX_ALLOWED_TANK_INDEX, currentIndex);
  };

  static getTankKeysFromConfig = (configuration: { [key: string]: unknown }): string[] => {
    const keys = Object.keys(configuration)
      .filter((key: string) => key.match(/(^Tank\d+(\.)*)./gi))
      .map((key) => key.split('.')[0]);
    return uniq(keys);
  };

  static getTanksFromConfiguration = (
    keys: string[],
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceTank[] =>
    uniq(keys).reduce((acc, key) => {
      const tank = DeviceTankHelpers.getTankInstance(key, device, modbusTable, pendingKeys, targetKeys, valueGetter, isCreated);
      return [...acc, tank];
    }, []);

  static getTankState = (tank: DeviceTank, modbusTable: ModbusTable): DeviceTankState => this.mapper.getConfigurationState(tank, modbusTable);

  // Return tank prefix key by index
  static getTankIndexKey = (tank: DeviceTank): string => `Tank${StringUtils.padWith(tank?.index, 2, '0')}`;

  // Return a new configuration copy without properties that match tank index
  static removePropertiesFrom = (
    tank: DeviceTank,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(tank, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get tank key
    const key = DeviceTankHelpers.getTankIndexKey(tank);
    return DeviceConfigurationHelpers.reduceConfiguration(configuration, key, targetConfiguration, false);
  };

  // Return all configuration properties that match tank index
  static getPropertiesFrom = (
    tank: DeviceTank,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(tank, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get tank key
    const key = DeviceTankHelpers.getTankIndexKey(tank);
    return DeviceConfigurationHelpers.reduceConfiguration(configuration, key, targetConfiguration, true);
  };

  // Tank that does not have an index or it's type is 0 are not configurable
  static canConfigureTank = (tank: DeviceTank) =>
    tank.index !== null && tank.index !== undefined && tank.type?.value !== 0 && !Object.keys(get(tank.device, ['configuration', 'target'], {})).length;

  static canDuplicateTank = (configuration: DeviceConfiguration) => {
    const index = this.getAvailableNextIndex(configuration);
    return index <= this.MAX_ALLOWED_TANK_INDEX && !Object.keys(get(configuration, ['target'], {})).length;
  };

  static deleteConfigurationFrom = (tank: DeviceTank, targetConfiguration: 'current' | 'pending' | 'target') => {
    const configuration = get(tank, ['device', 'configuration'], {});
    const currentKeys: string[] = DeviceTankHelpers.getTankKeysFromConfig(get(configuration, 'current', {}));
    const indexKey = DeviceTankHelpers.getTankIndexKey(tank);
    if (uniq(currentKeys).includes(indexKey)) {
      // Set type to 0
      return {
        ...get(configuration, [targetConfiguration], {}),
        [`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.type}`]: 0
      };
    }
    return DeviceTankHelpers.removePropertiesFrom(tank, targetConfiguration);
  };

  static hasCylinder = (type: number) => [2, 4, 6].includes(type);

  static duplicateConfigurationFrom = (tank: DeviceTank) => {
    const deviceConfiguration = DeviceConfigurationHelpers.mergeConfiguration(get(tank, ['device', 'configuration']), tank.status);
    const tankKey = this.getTankIndexKey(tank);
    const index = this.getAvailableNextIndex(deviceConfiguration);
    const newVariableKey = this.getTankIndexKey({ index } as DeviceTank);
    const configuration = DeviceConfigurationHelpers.reduceConfiguration(deviceConfiguration, tankKey, 'current', true);
    return Object.entries(configuration).reduce((acc, [key]) => {
      if (key.indexOf(`${tankKey}.`) !== -1) {
        const k = key.replace(tankKey, newVariableKey);
        acc[k] = get(configuration, [key]);
      }
      return { ...acc };
    }, {});
  };

  static cleanUpConfiguration = (
    indexKey: string,
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    const type = get(configuration, [`${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.type}`]) as number;
    const hasCylinder = DeviceTankHelpers.hasCylinder(type);
    const cylinderKey = `${indexKey}.${this.mapper.ATTRIBUTE_MAPPER.cylinderLength}`;

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      if (!(config[key] === currentValue)) {
        // Set cylinder value to 0 nin case tank does not allow cylinder
        if (key === cylinderKey && !hasCylinder) {
          acc[key] = undefined;
        } else {
          acc[key] = config[key];
        }
      }
      return { ...acc };
    }, {});
  };

  static getImageUrlByTank = (tank: DeviceTank) => {
    const imageUrl = get(DeviceTankHelpers.IMAGE_URL_MAPPER, [tank?.gasType?.value, tank?.type?.value]);
    return imageUrl ? `assets/images/asset/${imageUrl}` : null;
  };

  static hasVariableP = (tank: DeviceTank): boolean => tank?.variablePIndex !== undefined && tank?.variablePIndex !== null && tank?.variablePIndex !== 0;

  // Generate tank model instance
  private static getTankInstance(
    key: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceTank {
    const status = DeviceConfigurationHelpers.getStatusByKey(key, device?.configuration, pendingKeys, targetKeys);
    const index = DeviceConfigurationHelpers.parseIndex(key);

    const id = uuidv4();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tank: any = {
      id,
      index,
      status,
      device,
      isCreated
    };

    const mapping = this.mapper.getMappingModel({
      data: tank,
      modbusTable,
      valueGetter,
      configuration: get(tank, ['device', 'configuration'])
    });

    tank = {
      ...tank,
      ...mapping
    };

    // This is used as business rule to enable/disable MRT configuration
    const canConfigure = DeviceTankHelpers.canConfigureTank(tank);
    const canDuplicate = DeviceTankHelpers.canDuplicateTank(get(device, ['configuration'], {}) as DeviceConfiguration);
    const hasCleanConfiguration = this.mapper.hasCleanConfiguration(tank, modbusTable);
    const imageUrl = DeviceTankHelpers.getImageUrlByTank(tank);
    return {
      ...tank,
      imageUrl,
      canConfigure,
      canDuplicate,
      hasCleanConfiguration
    };
  }
}
