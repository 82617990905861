import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { EnergyMode, ModbusList, ModbusListItem, ModbusListName, ModbusMapping, ModbusTable, ModemType } from '@iot-platform/models/dalia';
import { get, uniqBy } from 'lodash';

export class ModbusTableHelpers {
  public static getModemTypes = () => [
    {
      label: ModemType.UNKNOWN,
      value: 0
    },
    {
      label: ModemType.PLS62,
      value: 1
    },
    {
      label: ModemType.EXS82,
      value: 2
    },
    {
      label: ModemType.PLS63,
      value: 3
    },
    {
      label: ModemType.EHS8,
      value: 4
    }
  ];

  public static getEnergyModes = () => [
    {
      value: 0,
      label: EnergyMode.FPM
    },
    {
      value: 1,
      label: EnergyMode.ECO1
    },
    {
      value: 2,
      label: EnergyMode.ECO2
    },
    {
      value: 3,
      label: EnergyMode.PSM
    },
    {
      value: 4,
      label: 'N/A'
    }
  ];

  public static getListItem(list: ModbusList, value: unknown): ModbusListItem {
    return list.find((item: ModbusListItem) => item.value === value);
  }

  public static getListItemByListName(dictionary: ModbusTable, listName: ModbusListName, value: unknown): ModbusListItem {
    const list: ModbusList = get(dictionary, ['lists', listName], []);
    return ModbusTableHelpers.getListItem(list, value);
  }

  public static getManyByListNames(
    dictionary: ModbusTable,
    options: {
      key: string;
      listName: ModbusListName;
      element: unknown;
    }[]
  ): { [key: string]: ModbusListItem } {
    return options.reduce((acc, { key, listName, element }) => {
      const value = get(element, key);
      const item = ModbusTableHelpers.getListItemByListName(dictionary, listName, value);
      return {
        ...acc,
        [key]: item
      };
    }, {});
  }

  public static getMappingByKey(dictionary: ModbusTable, key: string): ModbusMapping {
    return get(dictionary, ['mappings', key]);
  }

  public static getListByKey(dictionary: ModbusTable, key: ModbusListName): ModbusList {
    return get(dictionary, ['lists', key]);
  }

  public static getCallingHours(dictionary: ModbusTable): ModbusList {
    return get(dictionary, ['lists', ModbusListName.LstStartingDate]);
  }

  // Return eligible severities
  public static getSeverities(): ModbusList {
    return [
      { label: 'critical', value: 1 },
      { label: 'medium', value: 2 },
      { label: 'minor', value: 4 },
      { label: 'major', value: 11 }
    ];
  }

  // Return severity item
  public static getSeverityByValue(value: number): ModbusListItem {
    const found = ModbusTableHelpers.getSeverities().find((s) => s.value === value);
    if (found) {
      return found;
    }
    return { label: 'unknown_severity', value: 0 };
  }

  public static getSeverityClasses = (tagCategories: TagCategory[]): ModbusListItem[] => {
    const allClasses: ModbusListItem[] = tagCategories.reduce((acc, tag: TagCategory) => {
      const labels: ModbusListItem[] = tag.labels.map((tagLabel: TagLabel) => ({
        label: tagLabel.name,
        value: tagLabel.name
      }));
      return [...acc, ...labels];
    }, []);
    return uniqBy(allClasses, 'value');
  };

  public static getVariableTypes(): ModbusList {
    return [
      { label: 'DP', value: 0 },
      { label: 'P', value: 1 }
    ];
  }

  public static getBaudrates(): ModbusList {
    return [
      { label: '9600', value: 9600 },
      { label: '19200', value: 19200 },
      { label: '38400', value: 38400 },
      { label: '56600', value: 56600 },
      { label: '115200', value: 115200 }
    ];
  }

  // Return channel list name
  public static getChannelListName(kind: number): ModbusListName {
    // kind in [6, 7, 8, 9, 10, 11, 12, 13, 18, 26, 27, 28, 29, 30, 31, 32]
    let channelListName: ModbusListName = ModbusListName.LstChannelInternal;
    if (ModbusTableHelpers.ofTypeCalculation(kind)) {
      channelListName = ModbusListName.LstChannelCalculation;
    } else if (ModbusTableHelpers.ofTypeSensor(kind)) {
      channelListName = ModbusListName.LstChannelSensor;
    }
    return channelListName;
  }

  public static hasInputVar1 = (kind: number) => [19, 20, 21, 22, 23].includes(kind);

  public static hasInputVar2 = (kind: number) => [19, 20, 21, 22].includes(kind);

  public static hasSlopePeriod = (kind: number) => [23].includes(kind);

  public static hasVariableToCopy = (kind: number, modbusFunction: number) => ModbusTableHelpers.ofTypeModbus(kind) && [6, 16].includes(modbusFunction);

  public static hasTank = (kind: number) => [24].includes(kind);

  public static ofTypeDrasticChange = (kind: number) => [42].includes(kind);

  public static ofTypeAnalogical = (kind: number) => [1, 2, 3, 4, 5].includes(kind);

  public static ofTypeModbus = (kind: number) => [15, 16].includes(kind);

  public static modbusFunctionOfTypeWrite = (modbusFunction: number) => [6, 16].includes(modbusFunction);

  public static ofTypeInternal = (kind: number) => [6, 7, 8, 9, 10, 11, 12, 13, 18, 30, 31, 32].includes(kind);

  public static ofTypeCalculation = (kind: number) => ModbusTableHelpers.ofTypeDrasticChange(kind) || [19, 20, 21, 22, 23, 24, 25].includes(kind);

  public static ofTypeSensor = (kind: number) => [1, 2, 3, 4, 5, 15].includes(kind);

  public static ofTypeDebug = (kind: number) => [26, 27, 28, 29].includes(kind);

  public static ofTypeAnalogicalOrModbusOrInternalOrCalculation = (kind: number) =>
    ModbusTableHelpers.ofTypeAnalogical(kind) ||
    ModbusTableHelpers.ofTypeModbus(kind) ||
    ModbusTableHelpers.ofTypeInternal(kind) ||
    ModbusTableHelpers.ofTypeCalculation(kind);

  public static alarmOfTypeInsideOrOutsideInterval = (alarmType: number) => [4, 5].includes(alarmType);

  public static alarmOfTypeDifferent = (alarmType: number) => [6].includes(alarmType);

  public static alarmOfTypeEqual = (alarmType: number) => [7].includes(alarmType);

  /**
   if a user select one of the following VarXX.Modbus.tpe
   a new field becomes available to fill the bitmask value VarXX.Modbus.bitmask
   the new field is a binary value of 16 bits (ex : 4096)
   MB_BIT: 0
   MB_BIT_I: 1
   MB_BIT32L: 24
   MB_BIT32L_I: 25
   MB_BIT32H: 26
   MB_BIT32H_I: 27
   */
  public static hasBitmask = (modbusType: number) => [0, 1, 24, 25, 26, 27].includes(modbusType);
}
