<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="end center"
       style="padding: 10px 20px 10px 0px; color: #4b4a4a; border-top: 0">
    @if (pagination()?.maxCountReached) {
      <span style="margin: 0 20px; font-weight: 700">
        {{ 'AG_GRID.maxItemsCount' | translate: { value: pagination()?.total ?? 100000 } }}
      </span>
    }
  </div>

  <div fxFlex.lt-sm="100%" fxLayout="row" fxLayoutAlign="end center"
       style="padding: 10px 20px 10px 0px; color: #4b4a4a; border-top: 0">
    <span style="margin: 0 20px">{{ 'AG_GRID.pageSizeSelectorLabel' | translate }} : {{ pagination()?.limit }} </span>

    @if (pagination() && !pagination()?.maxCountReached) {
      <span style="margin: 0 20px">
      {{ pagination()?.currentPage * pagination()?.limit + 1 }}
        - {{ getTotalElements() }} {{ 'AG_GRID.of' | translate }} {{ pagination()?.total }}
      </span>
    }

    <button (click)="navigateFirst()" [disabled]="currentPage() === 0" mat-icon-button>
      <mat-icon>first_page</mat-icon>
    </button>

    <button (click)="navigatePrevious()" [disabled]="currentPage() === 0" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>

    <span>
      {{ 'AG_GRID.page' | translate }} {{ (currentPage() ? currentPage() + 1 : 1) | numberFormat }} @if (!pagination()?.maxCountReached) {
      {{ 'AG_GRID.of' | translate }} {{ (pagination()?.maxPage + 1) | numberFormat }}
    }
    </span>

    <button (click)="navigateNext()"
            [disabled]="(!pagination()?.maxCountReached && currentPage() === pagination()?.maxPage)" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>

    @if (!pagination()?.maxCountReached) {
      <button (click)="navigateLast()"
              [disabled]="pagination()?.hasMore === false || currentPage() === pagination()?.maxPage" mat-icon-button>
        <mat-icon>last_page</mat-icon>
      </button>
    }
  </div>
</div>
