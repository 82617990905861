import { Component, computed, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonGenericModel } from '@iot-platform/models/common';
import { MapMarkersHelper } from '../../helpers/map-markers.helper';
import { IotGeoJsonFeature } from '../../models/iot-geo-json-object.model';
import { SimpleMapComponent } from '../simple-map/simple-map.component';

@Component({
  imports: [FlexLayoutModule, SimpleMapComponent],
  selector: 'iot-platform-maps-geo-coordinates',
  styles: `
    .iot-platform-maps-geo-coordinates {
      min-height: 16rem;
    }
  `,
  template: `
    <iot-platform-maps-simple-map
      class="iot-platform-maps-geo-coordinates"
      [features]="mapFeatures()"
      [concept]="concept()"
      [zoom]="zoom()"
      data-cy="iot-platform-maps-geo-coordinates"
    />
  `
})
export class MapGeoCoordinatesComponent<T extends CommonGenericModel> {
  longitude = input.required<number>();
  latitude = input.required<number>();
  element = input.required<T>();
  concept = input.required<string>();
  zoom = input<number>(7);
  mapFeatures: Signal<IotGeoJsonFeature[]> = computed(() => {
    const concept = this.concept();
    const latitude = this.latitude();
    const longitude = this.longitude();
    const element = this.element();
    const coordinates: [number, number] = [longitude, latitude];
    const geoFeature = MapMarkersHelper.createMarkerFeatureFromCoordinates(coordinates, { ...element, concept });
    return [geoFeature];
  });
}
