import { CellClassParams } from '@ag-grid-community/core';
import { AssetVariableThreshold } from '@iot-platform/models/i4b';
import { get, isEmpty, orderBy } from 'lodash';
import { I4BCellType, I4BRichVariableColumnConfiguration } from '../configurations';
import { DataLibrary, I4bCellConcept } from '../enums';
import { I4BBasicColumnHeader } from '../headers';
import { HeaderType, I4BColumnHeader } from '../models';
import { BasicColumnOptions, I4BColumnOptions } from '../options';
import { I4BColumn } from './i4b-column';

export class RichVariableColumn implements I4BColumn<I4BBasicColumnHeader, I4BRichVariableColumnConfiguration, I4BColumnOptions> {
  columnId = '7a788742-6f09-11ec-87d5-acde48001122-rich-variable';
  className!: RichVariableColumn;

  dataLibs: DataLibrary[] = [DataLibrary.ASSETS];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC });
  configuration: I4BRichVariableColumnConfiguration = {
    concept: I4bCellConcept.ASSETS,
    id: null,
    isConfigurable: false,
    isDefault: true,
    isLink: true,
    position: null,
    sortProperty: null,
    width: 160,
    cell: {
      type: I4BCellType.RICH_VARIABLE,
      options: {
        dataType: '',
        unit: 'unit',
        value: 'value',
        tooltip: 'datetime'
      },
      event: { type: 'openChart', options: null }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: false });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BRichVariableColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }

    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }
    this.configuration.cell.cellStyle = this.cellStyle.bind(this);
  }

  static getNearestThreshold = (data, value): AssetVariableThreshold | null => {
    const operator = get(data, 'thresholds.operator');
    const thresholds = orderBy(get(data, 'thresholds.values'), ['value'], [operator === '<' ? 'desc' : 'asc']);

    let nearestThreshold = null;
    let smallestDifference = Infinity;

    thresholds.forEach((threshold) => {
      const targetValue = get(threshold, 'value');
      let isValid = false;
      switch (operator) {
        case '>':
          isValid = value > targetValue;
          break;
        case '<':
          isValid = value < targetValue;
          break;
        case '=':
          isValid = value === targetValue;
          break;
        default:
          throw new Error(`Invalid operator: ${operator}`);
      }
      if (isValid) {
        const difference = Math.abs(value - targetValue);
        if (difference < smallestDifference) {
          smallestDifference = difference;
          nearestThreshold = {
            ...threshold,
            operator,
            cellValue: value
          };
        }
      }
    });
    return nearestThreshold;
  };

  cellStyle(params: CellClassParams) {
    const data = get(params.data, params?.colDef?.field, params.data);
    let value = get(params, 'value');
    if (isEmpty(params?.value) && get(params, ['colDef', 'cellRendererParams', 'cellOptions', 'dataType']) === 'lastValue') {
      value = get(data, ['lastValue', 'value']);
    }
    const nearestThreshold = RichVariableColumn.getNearestThreshold(data, value);
    return nearestThreshold ? { backgroundColor: nearestThreshold?.cellColor } : null;
  }
}
