import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, input, Signal, ViewEncapsulation } from '@angular/core';

/**
 * Skeleton is a placeholder to display instead of the actual content.
 * @group Components
 *
 * <div fxLayout="column" fxLayoutGap="10px">
 *
 *   <h5>Rectangle</h5>
 *   <div fxLayout="column" fxLayoutAlign="start">
 *     <iot-platform-ui-skeleton styleClass="mb-2" />
 *     <iot-platform-ui-skeleton styleClass="mb-2" width="10rem" />
 *     <iot-platform-ui-skeleton styleClass="mb-2" width="5rem" />
 *     <iot-platform-ui-skeleton height="2rem" styleClass="mb-2" />
 *     <iot-platform-ui-skeleton height="4rem" width="10rem" />
 *   </div>
 *
 *   <h5>Rounded</h5>
 *   <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
 *     <iot-platform-ui-skeleton borderRadius="16px" styleClass="mb-2" />
 *     <iot-platform-ui-skeleton borderRadius="16px" styleClass="mb-2" width="10rem" />
 *     <iot-platform-ui-skeleton borderRadius="16px" styleClass="mb-2" width="5rem" />
 *     <iot-platform-ui-skeleton borderRadius="16px" height="2rem" styleClass="mb-2" />
 *     <iot-platform-ui-skeleton borderRadius="16px" height="4rem" width="10rem" />
 *   </div>
 *
 *   <h5 class="mt-3">Square</h5>
 *   <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
 *     <iot-platform-ui-skeleton size="2rem" />
 *     <iot-platform-ui-skeleton size="3rem" />
 *     <iot-platform-ui-skeleton size="4rem" />
 *     <iot-platform-ui-skeleton size="5rem" />
 *   </div>
 *
 *   <h5 class="mt-3">Circle</h5>
 *   <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
 *     <iot-platform-ui-skeleton shape="circle" size="2rem" />
 *     <iot-platform-ui-skeleton shape="circle" size="3rem" />
 *     <iot-platform-ui-skeleton shape="circle" size="4rem" />
 *     <iot-platform-ui-skeleton shape="circle" size="5rem" />
 *   </div>
 *
 *   <h5 class="mt-3">Card</h5>
 *   <div fxLayout="column" fxLayoutGap="10px">
 *     <div fxLayout="row" fxLayoutGap="5px">
 *       <iot-platform-ui-skeleton shape="circle" size="4rem" />
 *       <div fxLayout="column" fxLayoutGap="5px">
 *         <iot-platform-ui-skeleton width="10rem" />
 *         <iot-platform-ui-skeleton width="5rem" />
 *         <iot-platform-ui-skeleton height=".5rem" />
 *       </div>
 *     </div>
 *     <iot-platform-ui-skeleton height="150px" width="100%" />
 *     <div fxFlex fxLayoutAlign="space-between start">
 *       <iot-platform-ui-skeleton height="2rem" width="4rem" />
 *       <iot-platform-ui-skeleton height="2rem" width="4rem" />
 *     </div>
 *   </div>
 *
 * </div>
 *
 */
@Component({
  standalone: true,
  selector: 'iot-platform-ui-skeleton',
  template: `
    <div
      [ngClass]="containerClass()"
      [class]="styleClass()"
      [ngStyle]="containerStyle()"
      [attr.data-pc-name]="'skeleton'"
      [attr.aria-hidden]="true"
      [attr.data-pc-section]="'root'"
    ></div>
  `,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./skeleton.component.scss'],
  imports: [NgClass, NgStyle],
  host: {
    class: 'iot-platform-ui-element'
  }
})
export class SkeletonComponent {
  /**
   * Class of the element.
   * @group Props
   */
  styleClass = input<string | undefined>();
  /**
   * Inline style of the element.
   * @group Props
   */
  style = input<{ [klass: string]: any } | null | undefined>();
  /**
   * Shape of the element.
   * @group Props
   */
  shape = input<string>('rectangle');
  /**
   * Type of the animation.
   * @gruop Props
   */
  animation= input<string>('wave');
  /**
   * Border radius of the element, defaults to value from theme.
   * @group Props
   */
  borderRadius = input<string | undefined>();
  /**
   * Size of the skeleton.
   * @group Props
   */
  size = input<string | undefined>();
  /**
   * Width of the element.
   * @group Props
   */
  width = input<string>('100%');
  /**
   * Height of the element.
   * @group Props
   */
  height = input<string>('1rem');

  containerStyle: Signal<any> = computed(() => {
    const size = this.size();
    const style = this.style();
    const borderRadius = this.borderRadius();
    const width = this.width();
    const height = this.height();
    if (size) return { ...style, width: size, height: size, borderRadius };
    else return { width, height, borderRadius, ...style };
  });

  containerClass: Signal<any> = computed(() => {
    const shape = this.shape();
    const animation = this.animation();
    return {
      'iot-platform-ui-skeleton iot-platform-ui-component': true,
      'iot-platform-ui-skeleton-circle': shape === 'circle',
      'iot-platform-ui-skeleton-none': animation === 'none'
    };
  });
}
