export const commonEnvironment = {
  production: false,
  sso: {
    subdomain: 'iot4bos-dev',
    domain: 'https://iot4bos-dev.auth.eu-west-1.amazoncognito.com',
    clientId: '4d8bpmnelnf2mkljakfakrvpub',
    idpIdentifier: 'okta',
    responseType: 'code',
    redirectUri: 'login/callback'
  },
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_3kPklo82Y',
    clientId: '4d8bpmnelnf2mkljakfakrvpub'
  },
  api: {
    url: '/api/v1',
    url_v2: '/api/v2',
    key: '',
    endpoints: {
      devices: '/devices',
      daliaDevices: '/devices?deviceIncomingConnectorId=SYN002&deviceTypeFamily=DALIA&expandVariableName=bl&expandVariableName=em&expandVariableName=pws',
      deviceDetails: '/device-details',
      deviceTemplates: '/device-templates',
      sites: '/sites',
      users: '/users',
      connector: '/connector',
      firmwares: '/firmwares',
      sensors: '/sensors/input-models',
      usersLightWeight: '/directory/users',
      modbusTable: '/directory/modbus-table',
      modemRadioBands: '/directory/modem-radio-bands',
      energyModes: '/directory/energy-modes',
      affiliates: '/directory/entity-affiliates',
      tags: '/tag-categories',
      bulkDeleteDeviceVariables: '/operations/devices-variables/bulk-delete'
    }
  },
  storage: {
    appPrefix: 'dalia'
  },
  apiKeys: {
    agGrid:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-050013}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ALIZENT_INTERNATIONAL}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Iot4Bos}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Iot4Bos}_need_to_be_licensed___{Iot4Bos}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_January_2025}____[v3]_[01]_MTczNjY0MDAwMDAwMA==146e14b0942e853d657817a9e7490fc0'
  }
};
