import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  DynamicIconColumn,
  EventVariableGroupColumn,
  I4BAssetEventsButtonColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BSelectionColumn,
  TotalColumn
} from './definitions';
import { I4bCellConcept } from './enums';
import { AssetEventsGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  new EventVariableGroupColumn(
    {
      displayName: 'ASSET VARIABLE'
    },
    { id: 'assetVariable', isDefault: true, concept: I4bCellConcept.ASSETS },
    { order: 5 }
  )
];

export const GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  new DynamicIconColumn({ displayName: 'DELAY', type: HeaderType.ICON, icon: 'delay' }, {}, { order: 1 }),
  new I4BBasicColumn(
    { displayName: 'OCCURRENCE TIME', type: HeaderType.BASIC },
    {
      id: 'occurrenceTime',
      isDefault: true,
      sortProperty: 'occurrenceTime',
      cell: { type: I4BCellType.DATE },
      concept: I4bCellConcept.EVENTS
    },
    { order: 2 }
  ),
  new I4BBasicColumn(
    { displayName: 'RECEPTION TIME', type: HeaderType.BASIC },
    {
      id: 'receptionTime',
      isDefault: true,
      sortProperty: 'receptionTime',
      cell: { type: I4BCellType.DATE },
      concept: I4bCellConcept.EVENTS
    },
    { order: 3 }
  ),
  new I4BBasicColumn(
    {
      displayName: 'LABEL',
      type: HeaderType.BASIC
    },
    {
      id: 'label',
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LABEL',
      isDefault: true,
      sortProperty: 'label',
      cell: { type: I4BCellType.BASIC }
    },
    { order: 6 }
  ),
  new I4BBasicColumn(
    {
      displayName: 'EVENT TYPE',
      type: HeaderType.BASIC
    },
    {
      id: 'type',
      concept: I4bCellConcept.EVENTS,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EVENT_NOTE',
      isDefault: true,
      sortProperty: 'type',
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'EVENTS.EVENT_TYPES.' } }
    },
    { order: 8 }
  ),
  new I4BBasicColumn(
    { displayName: 'SEVERITY', type: HeaderType.BASIC },
    {
      id: 'severity',
      isDefault: true,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SEVERITY',
      sortProperty: 'severity',
      cell: { type: I4BCellType.SEVERITY_CELL },
      concept: I4bCellConcept.EVENTS
    },
    { order: 9 }
  ),
  new TotalColumn(
    {
      displayName: 'COMMENTS',
      type: HeaderType.EMPTY
    },
    {
      id: 'totalComments',
      isDefault: true,
      isConfigurable: false,
      cell: { type: I4BCellType.COMMENT },
      concept: I4bCellConcept.EVENTS
    },
    { order: 11 }
  ),
  new I4BBasicColumn(
    { displayName: 'EVENT STATUS', type: HeaderType.ICON, icon: 'event_alarm' },
    {
      id: 'status',
      isDefault: true,
      isConfigurable: false,
      sortProperty: 'status',
      concept: I4bCellConcept.EVENTS,
      cell: { type: I4BCellType.ICON, options: { tooltip: 'EVENTS.STATUS' } }
    },
    { order: 0 }
  )
];

export class DefaultAssetEventsByAssetGrid implements I4BGrid<I4BGridOptions, AssetEventsGridData> {
  masterview = 'asset-events-by-asset';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name!: string;
  userId!: string;
  data!: AssetEventsGridData;
  gridOptions: I4BGridOptions = {
    endPoint: '/asset-events',
    pageSize: 100,
    multipleSelection: true,
    autoRefresh: { enabled: false, delay: 120 },
    gridSort: [],
    buttonColumn: { enabled: true, className: new I4BAssetEventsButtonColumn() },
    selectionColumn: {
      enabled: true,
      className: new I4BSelectionColumn()
    }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
