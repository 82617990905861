import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState,
  ModemRadioBands
} from '@iot-platform/models/dalia';
import { cloneDeep, get } from 'lodash';
import { DeviceConfigurationHelpers } from './device-configuration.helpers'; /* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum FieldType {
  BASIC = 'BASIC',
  COMPLEX = 'COMPLEX'
}

export interface FieldMappingModelOptions<T> {
  data: T;
  modbusTable: ModbusTable;
  modemRadioBands: ModemRadioBands;
  configuration: DeviceConfiguration;
  valueGetter: (key: string) => string[];
  pathPrefix: string;
  mergeConfig: boolean;
  status: DeviceConfigurationStatus;
}

export interface Field {
  type: FieldType;
  name: string;
  level?: number;
  parentField?: Field;
  path?: string;
  suppressPropertyStateTransform?: boolean;
  suppressPropertyTransform?: boolean;
  suppressPropertyStateCompute?: boolean;
  suppressPropertyCompute?: boolean;
  suppressPropertyStateMap?: boolean;
  suppressPropertyMap?: boolean;
  transformField?: (
    options: Partial<{
      field: Field;
      data: any;
      modbusTable: ModbusTable;
      modemRadioBands: ModemRadioBands;
      configuration: DeviceConfiguration;
      value: any;
      valueGetter: (key: string) => any;
    }>
  ) => any;
  transformValue?: (
    options: Partial<{
      field: Field;
      parentField: Field;
      data: any;
      modbusTable: ModbusTable;
      modemRadioBands: ModemRadioBands;
      configuration: DeviceConfiguration;
      value: any;
      valueGetter: (key: string) => any;
    }>
  ) => any;
  mapped?: boolean;
  modbusList?: ModbusListName;
  computed?: boolean;
  computedVariable?: boolean;
  optional?: boolean;
  children?: Field[];
}

export const VARIABLE_NAME_ATTRIBUTE_MAPPER = 'Variable_name';

export abstract class AbstractMapper<T, K, S> {
  abstract get fields(): Field[];

  abstract get stateDataMapping();

  abstract get dataMapping();

  // TODO this could be refactored and centralized
  abstract getPropertyState(field: Field, data: T, modbusTable: ModbusTable): ModbusTablePropertyState;

  abstract getMappingModel(options: Partial<FieldMappingModelOptions<T>>): K;

  applyMapping(options: Partial<FieldMappingModelOptions<T>>): K {
    const status = get(options, ['status'], DeviceConfigurationStatus.CURRENT);
    const configuration = cloneDeep(get(options, ['configuration'], {})) as DeviceConfiguration;
    if (options.mergeConfig) {
      if (status === DeviceConfigurationStatus.PENDING) {
        configuration.current = DeviceConfigurationHelpers.mergeConfigValues({ ...configuration }, 'pending');
      } else if (status === DeviceConfigurationStatus.PUBLISHED) {
        configuration.current = DeviceConfigurationHelpers.mergeConfigValues({ ...configuration }, 'target');
      }
    }
    const processFields = (items: Field[], parentField?: Field) =>
      items.reduce((acc, field: Field) => {
        if (field.transformField) {
          field = field.transformField({
            ...options,
            field,
            configuration
          });
        }

        if (field.children) {
          return {
            ...acc,
            [field.name]: processFields(field.children, field)
          };
        }

        const fullPath = this.getFullPath(options.pathPrefix, field.path);
        let value = get(configuration, options.valueGetter(fullPath));

        if (field.transformValue && !field?.suppressPropertyTransform) {
          value = field.transformValue({
            ...options,
            field,
            parentField,
            configuration,
            value
          });
        }
        if (field.modbusList && field.mapped && !field?.suppressPropertyMap) {
          value = ModbusTableHelpers.getListItemByListName(options.modbusTable, field.modbusList, value);
        } else if (field.computed && !field?.suppressPropertyCompute) {
          value = this.getComputedProperty(field, value /* computed key */, configuration, options.valueGetter, options.pathPrefix);
        }
        return {
          ...acc,
          [field.name]: value
        };
      }, {});

    const fields = this.processMapping(this.fields, this.dataMapping);
    return processFields(fields);
  }

  getComputedProperty(field: Field, computedKey: string, configuration: DeviceConfiguration, valueGetter: (k: string) => string[], pathPrefix?: string) {
    const path = this.getFullPath(pathPrefix, field.path);
    const value = get(configuration, valueGetter(path));

    if (field.computedVariable) {
      computedKey = `Var${StringUtils.padWith(value, 2, '0')}.${VARIABLE_NAME_ATTRIBUTE_MAPPER}`;
    }

    // In case computed value found and empty return initial value
    // Otherwise return calculated value
    const result = get(configuration, valueGetter(computedKey));
    if (result !== undefined) {
      if (result === '') {
        return value;
      }
      return result;
    }
    // In case computed value is not found
    // Look for related index in all configuration
    return get(configuration, ['target', computedKey], get(configuration, ['pending', computedKey], get(configuration, ['current', computedKey, 'v'], value)));
  }

  flattenFields(fields: Field[], level = 0, parent: Field | null = null): Field[] {
    let flatArray: Field[] = [];
    fields.forEach((field) => {
      // Create the current field with level and parent details
      const flatField: Field = {
        ...field,
        level,
        parentField: parent || undefined // Add parent object or undefined if top-level
      };

      // Add the current field to the flat array
      flatArray.push(flatField);

      // If the field has children, recursively flatten them and pass the current field as parent
      if (field.children) {
        flatArray = flatArray.concat(this.flattenFields(field.children, level + 1, flatField));
      }
    });

    return flatArray;
  }

  getField(fieldName: string, parentFieldName?: string): Field {
    return this.flattenFields(this.fields).find((child) => child.name === fieldName && child.parentField?.name === parentFieldName);
  }

  getPropertyStateDisplayOldValue = (
    field: Field,
    modbusTable: ModbusTable,
    data: T,
    oldValue: unknown,
    configuration: DeviceConfiguration,
    valueGetter: (k: string) => string[],
    key?: string
  ) => {
    let displayValue = oldValue;
    if (field.transformValue && field?.suppressPropertyStateTransform) {
      displayValue = field.transformValue({
        field,
        modbusTable,
        data,
        value: oldValue,
        configuration,
        valueGetter
      });
    }
    if (field.mapped && field.modbusList && !field?.suppressPropertyStateMap) {
      displayValue = ModbusTableHelpers.getListItemByListName(modbusTable, field.modbusList, oldValue);
    } else if (field.computed && field?.suppressPropertyStateCompute) {
      displayValue = this.getComputedProperty(field, displayValue as string, configuration, valueGetter, key);
    }
    return get(displayValue, ['label'], displayValue);
  };

  getConfigurationState(data: T, modbusTable: ModbusTable, configStatus?: DeviceConfigurationStatus): S {
    const status = configStatus ?? get(data, 'status', DeviceConfigurationStatus.CURRENT);
    const getIsPendingOrTarget = (obj) => {
      const isPendingOrTarget = Object.values(obj)
        .map((item: any) => item.status === DeviceConfigurationStatus.PENDING || item.status === DeviceConfigurationStatus.PUBLISHED)
        .some((v) => !!v);
      if (isPendingOrTarget) {
        return status;
      }
      return DeviceConfigurationStatus.CURRENT;
    };

    const fn = (items: Field[]) =>
      items.reduce((acc, field: Field) => {
        if (field.children) {
          const attributes = fn(field.children);
          return {
            ...acc,
            [field.name]: {
              attributes,
              status: getIsPendingOrTarget(attributes)
            }
          };
        }
        return {
          ...acc,
          [field.name]: this.getPropertyState(field, data, modbusTable)
        };
      }, {});
    const fields = this.processMapping(this.fields, this.stateDataMapping);
    const attrs = fn(fields);
    return {
      ...attrs,
      status
    };
  }

  hasCleanConfiguration(data: T, modbusTable: ModbusTable): boolean {
    const fields = this.fields;
    const flattened = this.flattenFields(fields);
    const hasDeletedProp = flattened.some((field) => {
      const state = this.getPropertyState(field, data, modbusTable);
      return get(state, ['status']) === DeviceConfigurationStatus.DELETED;
    });
    return !hasDeletedProp;
  }

  private getFullPath(pathPrefix: string | undefined, path: string): string {
    return pathPrefix ? `${pathPrefix}.${path}` : path;
  }

  private processMapping(fields: Field[], mapping: any) {
    return fields.reduce((acc, field) => {
      const fieldName = field.name;

      // Check if the field name exists in the mapping
      if (mapping[fieldName]) {
        let mappedField: any = {
          name: fieldName,
          type: field.type
        };

        if (field.children && typeof mapping[fieldName] === 'object') {
          mappedField.children = this.processMapping(field.children, mapping[fieldName]);
        } else if (typeof mapping[fieldName] === 'boolean' && mapping[fieldName]) {
          mappedField = {
            ...mappedField,
            ...field
          };
        }

        // Add the mapped field to the result array
        acc.push(mappedField);
      } else if (field.children) {
        acc = acc.concat(this.processMapping(field.children, mapping));
      }
      return acc;
    }, []);
  }
}
