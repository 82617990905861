import { Injectable } from '@angular/core';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { DeviceConfigurationStatus, DeviceVariable } from '@iot-platform/models/dalia';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import * as fromDeviceVariables from '../reducers/device-variables.reducer';

@Injectable({
  providedIn: 'root'
})
export class DeviceVariablesSelectors extends ApiFeatureSelector<fromDeviceVariables.State, DeviceVariable, Pagination, Filter> {
  selectDaliaVariables: MemoizedSelector<fromDeviceVariables.State, DeviceVariable[]> = createSelector(
    this.selectState,
    (state: fromDeviceVariables.State) => state.daliaVariableList
  );

  selectVariablesToBeDeleted: MemoizedSelector<fromDeviceVariables.State, DeviceVariable[]> = createSelector(
    this.selectAll,
    (variables: DeviceVariable[]): DeviceVariable[] => variables.filter((v) => v.status === DeviceConfigurationStatus.DELETED && !v.linked)
  );

  constructor() {
    super(fromDeviceVariables.deviceVariablesFeature.selectDeviceVariablesState, fromDeviceVariables.adapter);
  }
}
