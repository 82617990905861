import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import {
  DeviceConfiguration,
  DeviceConfigurationState,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceSettings,
  ModbusListItem,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState,
  ModemType
} from '@iot-platform/models/dalia';
import { get } from 'lodash';
import { AbstractMapper, Field, FieldMappingModelOptions, FieldType } from './abstract-mapper';
import { DeviceConfigurationHelpers } from './device-configuration.helpers';
import { DeviceVariableHelpers } from './device-variable.helpers';
import { DeviceHelpers } from './device.helpers';

export class DeviceMapper extends AbstractMapper<DeviceDetails, DeviceSettings, DeviceConfigurationState> {
  readonly ENERGY_ATTRIBUTE_MAPPER = {
    acquisitionPeriod: (k: string): string => `Energy.${k}_acq_period`,
    transmissionPeriod: (k: string): string => `Energy.${k}_tx_period`,
    recordPeriod: (k: string): string => `Energy.${k}_rec_period`,
    diagnosticPeriod: (k: string): string => `Energy.${k}_diag_period`,
    listeningTime: (k: string): string => `Energy.${k}_listening_time`,
    fpmToEco1Threshold: 'Energy.FPM_to_ECO1_threshold',
    eco1ToEco2Threshold: 'Energy.ECO1_to_ECO2_threshold',
    eco2ToPsmThreshold: 'Energy.ECO2_to_PSM_threshold',
    energyModeHysteresis: 'Energy.Energy_mode_hysteresis',
    psmHysteresis: 'Energy.PSM_Hysteresis'
  };

  readonly COMMUNICATION_ATTRIBUTE_MAPPER = {
    [ModemType.PLS62]: {
      radioBand_2G: 'COM.Radio_band_2G',
      radioBand_3G: 'COM.Radio_band_3G',
      radioBand_4G: 'COM.Radio_band_4G'
    },
    [ModemType.PLS63]: {
      radioBand_PLS63_2G: 'COM.Radio_band_PLS63_2G',
      radioBand_PLS63_3G: 'COM.Radio_band_PLS63_3G',
      radioBand_PLS63_4G_L: 'COM.Radio_band_PLS63_4G_L',
      radioBand_PLS63_4G_H_L: 'COM.Radio_band_PLS63_4G_H_L',
      radioBand_PLS63_4G_H_H: 'COM.Radio_band_PLS63_4G_H_H'
    },
    [ModemType.EXS82]: {
      radioBand_EXS_2G: 'COM.Radio_band_EXS_2G',
      radioBand_EXS_LTEM: 'COM.Radio_band_EXS_LTEM',
      radioBand_EXS_LTEM_H: 'COM.Radio_band_EXS_LTEM_H',
      radioBand_EXS_NBIOT: 'COM.Radio_band_EXS_NBIOT',
      radioBand_EXS_NBIOT_H: 'COM.Radio_band_EXS_NBIOT_H'
    },
    [ModemType.EHS8]: {
      radioBand_2G: 'COM.Radio_band_2G'
    }
  };

  readonly ATTRIBUTE_MAPPER = {
    generalInformation: {
      name: 'GenParam.Device_name'
    },
    callingHour: {
      startingDate: 'GenParam.Starting_date'
    },
    autonomy: {
      maxAutonomy: 'Autonomy.maxAutonomy',
      nbDaysWindow: 'Autonomy.nbDaysWindow',
      fillLevelFilter: 'Autonomy.fillLevelFilter',
      backupPercentage: 'Autonomy.Backup_percentage',
      recordPeriod: 'Autonomy.Record_period',
      indexBackupStatus: 'Autonomy.Index_backup_status',
      indexCylinderP: 'Autonomy.Index_cylinder_P',
      indexCylinderT: 'Autonomy.Index_cylinder_T',
      nbCylinderB50: 'Autonomy.Nb_cylinder_B50',
      nbV9packs: 'Autonomy.Nb_V9packs'
    },
    energy: {
      ...this.ENERGY_ATTRIBUTE_MAPPER
    },
    modbus: {
      role: 'GenParam.Modbus_role',
      slaveAddress: 'GenParam.Device_modbus_address'
    },
    communication: {
      modem: 'COM.modemModel',
      ...this.COMMUNICATION_ATTRIBUTE_MAPPER
    },
    alarm: {
      defaultSensorLevel: 'GenParam.Default_sensor_level',
      fillingAlarmLevel: 'GenParam.Filling_alarm_level',
      maintenanceAlarmLevel: 'GenParam.Maintenance_alarm_level',
      transportAlarmLevel: 'GenParam.Transport_alarm_Level',
      energyAlarmLevel: 'GenParam.Energy_alarm_level',
      powerAlarmLevel: 'GenParam.Power_alarm_level',
      defaultSensorClass: 'GenParam.Default_sensor_Class',
      fillingAlarmClass: 'GenParam.Filling_alarm_Class',
      maintenanceAlarmClass: 'GenParam.Maintenance_alarm_Class',
      transportAlarmClass: 'GenParam.Transport_alarm_Class',
      energyAlarmClass: 'GenParam.Energy_alarm_Class',
      powerSupplyAlarm: 'GenParam.Power_supply_alarm',
      powerAlarmClass: 'GenParam.Power_alarm_Class'
    },
    gps: {
      longitude: 'GenInfo.Dyn.GPS_longitude',
      latitude: 'GenInfo.Dyn.GPS_latitude',
      cardinalLongitude: 'GenInfo.Dyn.GPS_cardinal_longitude',
      cardinalLatitude: 'GenInfo.Dyn.GPS_cardinal_latitude'
    },
    hardware: {
      bleChipMacAddress: 'GenInfo.BLE_mac_address_string',
      bleSoftwareVersion: 'GenInfo.Soft_version',
      modemRevision: 'GenInfo.Modem_Revision',
      modemARevision: 'GenInfo.Modem_A_Revision',
      hardwareVersion: 'GenInfo.HardwareVersion'
    },
    tank: {
      rollingTankEnable: 'GenParam.RollingTankEnable',
      rollingTankPeriod: 'GenParam.RollingTankPeriod'
    }
  };

  readonly STATE_DATA_MAPPING = {
    generalInformation: {
      name: true
    },
    callingHour: {
      startingDate: true,
      startingHour: true
    },
    autonomy: {
      maxAutonomy: true,
      nbDaysWindow: true,
      fillLevelFilter: true,
      backupPercentage: true,
      recordPeriod: true,
      indexBackupStatus: true,
      indexCylinderP: true,
      indexCylinderT: true,
      nbCylinderB50: true,
      nbV9packs: true
    },
    energy: {
      mode: true,
      ...ModbusTableHelpers.getEnergyModes().reduce(
        (acc, item) => ({
          ...acc,
          [item.label]: {
            mode: true,
            acquisitionPeriod: true,
            transmissionPeriod: true,
            recordPeriod: true,
            diagnosticPeriod: true,
            listeningTime: true,
            fpmToEco1Threshold: true,
            eco1ToEco2Threshold: true,
            eco2ToPsmThreshold: true,
            energyModeHysteresis: true,
            psmHysteresis: true
          }
        }),
        {}
      )
    },
    modbus: {
      role: true,
      slaveAddress: true
    },
    communication: {
      modem: true,
      [ModemType.PLS62]: {
        radioBand_2G: true,
        radioBand_3G: true,
        radioBand_4G: true
      },
      [ModemType.PLS63]: {
        radioBand_PLS63_2G: true,
        radioBand_PLS63_3G: true,
        radioBand_PLS63_4G_L: true,
        radioBand_PLS63_4G_H_L: true,
        radioBand_PLS63_4G_H_H: true
      },
      [ModemType.EXS82]: {
        radioBand_EXS_2G: true,
        radioBand_EXS_LTEM: true,
        radioBand_EXS_LTEM_H: true,
        radioBand_EXS_NBIOT: true,
        radioBand_EXS_NBIOT_H: true
      },
      [ModemType.EHS8]: {
        radioBand_2G: true
      }
    },
    alarm: {
      defaultSensorLevel: true,
      fillingAlarmLevel: true,
      maintenanceAlarmLevel: true,
      transportAlarmLevel: true,
      energyAlarmLevel: true,
      powerAlarmLevel: true,
      defaultSensorClass: true,
      fillingAlarmClass: true,
      maintenanceAlarmClass: true,
      transportAlarmClass: true,
      energyAlarmClass: true,
      powerSupplyAlarm: true,
      powerAlarmClass: true
    },
    gps: {
      longitude: true,
      latitude: true,
      cardinalLongitude: true,
      cardinalLatitude: true
    },
    hardware: {
      bleChipMacAddress: true,
      bleSoftwareVersion: true,
      modemRevision: true,
      modemARevision: true,
      hardwareVersion: true
    },
    tank: {
      rollingTankEnable: true,
      rollingTankPeriod: true
    }
  };

  readonly DATA_MAPPING = {
    ...this.STATE_DATA_MAPPING
  };

  readonly FIELDS: Field[] = [
    {
      type: FieldType.COMPLEX,
      name: 'generalInformation',
      children: [
        {
          name: 'name',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.generalInformation.name
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'callingHour',
      children: [
        {
          name: 'startingDate',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.callingHour.startingDate
        },
        {
          name: 'startingHour',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.callingHour.startingDate,
          transformValue: ({ modbusTable, value: startingDate }) => DeviceHelpers.getCallingHour(startingDate, modbusTable)
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'autonomy',
      children: [
        {
          name: 'maxAutonomy',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.maxAutonomy
        },
        {
          name: 'nbDaysWindow',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.nbDaysWindow
        },
        {
          name: 'fillLevelFilter',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.fillLevelFilter
        },
        {
          name: 'backupPercentage',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.backupPercentage
        },
        {
          name: 'recordPeriod',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.recordPeriod
        },
        {
          name: 'indexBackupStatus',
          type: FieldType.BASIC,
          computed: true,
          computedVariable: true,
          optional: true,
          path: this.ATTRIBUTE_MAPPER.autonomy.indexBackupStatus
        },
        {
          name: 'indexCylinderP',
          type: FieldType.BASIC,
          computed: true,
          optional: true,
          computedVariable: true,
          path: this.ATTRIBUTE_MAPPER.autonomy.indexCylinderP
        },
        {
          name: 'indexCylinderT',
          type: FieldType.BASIC,
          computed: true,
          computedVariable: true,
          optional: true,
          path: this.ATTRIBUTE_MAPPER.autonomy.indexCylinderT
        },
        {
          name: 'nbCylinderB50',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.nbCylinderB50
        },
        {
          name: 'nbV9packs',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.autonomy.nbV9packs
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'energy',
      children: [
        {
          type: FieldType.BASIC,
          name: 'mode',
          transformValue: ({ data: device }) => DeviceHelpers.getEnergyMode(device)
        },
        ...ModbusTableHelpers.getEnergyModes().map(
          (item) => ({
            type: FieldType.COMPLEX,
            name: item.label,
            children: [
              {
                type: FieldType.BASIC,
                name: 'mode',
                transformValue: () => item
              },
              {
                name: 'acquisitionPeriod',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.acquisitionPeriod(item.label)
              },
              {
                name: 'transmissionPeriod',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.transmissionPeriod(item.label)
              },
              {
                name: 'recordPeriod',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.recordPeriod(item.label)
              },
              {
                name: 'diagnosticPeriod',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.diagnosticPeriod(item.label)
              },
              {
                name: 'listeningTime',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.listeningTime(item.label)
              },
              {
                name: 'fpmToEco1Threshold',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.fpmToEco1Threshold
              },
              {
                name: 'eco1ToEco2Threshold',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.eco1ToEco2Threshold
              },
              {
                name: 'eco2ToPsmThreshold',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.eco2ToPsmThreshold
              },
              {
                name: 'energyModeHysteresis',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.energyModeHysteresis
              },
              {
                name: 'psmHysteresis',
                type: FieldType.BASIC,
                path: this.ATTRIBUTE_MAPPER.energy.psmHysteresis
              }
            ]
          }),
          {}
        )
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'modbus',
      children: [
        {
          name: 'role',
          type: FieldType.BASIC,
          modbusList: ModbusListName.LstModbusRole,
          path: this.ATTRIBUTE_MAPPER.modbus.role,
          mapped: true
        },
        {
          name: 'slaveAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbus.slaveAddress
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'communication',
      children: [
        {
          name: 'modem',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.communication.modem,
          transformValue: ({ modbusTable, value }) => {
            const modemItem = ModbusTableHelpers.getListItemByListName(modbusTable, ModbusListName.LstModem, value);
            const modem: ModbusListItem = modemItem ?? { value, label: ModemType.EHS8 };
            return modem;
          }
        },
        {
          name: ModemType.PLS62,
          type: FieldType.COMPLEX,
          children: [
            {
              name: 'radioBand_2G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS62].radioBand_2G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS62);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_3G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS62].radioBand_3G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS62);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_4G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS62].radioBand_4G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS62);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            }
          ]
        },
        {
          name: ModemType.PLS63,
          type: FieldType.COMPLEX,
          children: [
            {
              name: 'radioBand_PLS63_2G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS63].radioBand_PLS63_2G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS63);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_PLS63_3G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS63].radioBand_PLS63_3G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS63);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_PLS63_4G_L',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS63].radioBand_PLS63_4G_L,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS63);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_PLS63_4G_H_L',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS63].radioBand_PLS63_4G_H_L,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS63);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_PLS63_4G_H_H',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.PLS63].radioBand_PLS63_4G_H_H,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.PLS63);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            }
          ]
        },
        {
          name: ModemType.EXS82,
          type: FieldType.COMPLEX,
          children: [
            {
              name: 'radioBand_EXS_2G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.EXS82].radioBand_EXS_2G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.EXS82);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_EXS_LTEM',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.EXS82].radioBand_EXS_LTEM,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.EXS82);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_EXS_LTEM_H',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.EXS82].radioBand_EXS_LTEM_H,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.EXS82);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_EXS_NBIOT',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.EXS82].radioBand_EXS_NBIOT,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.EXS82);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            },
            {
              name: 'radioBand_EXS_NBIOT_H',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.EXS82].radioBand_EXS_NBIOT_H,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.EXS82);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            }
          ]
        },
        {
          name: ModemType.EHS8,
          type: FieldType.COMPLEX,
          children: [
            {
              name: 'radioBand_2G',
              type: FieldType.BASIC,
              path: this.ATTRIBUTE_MAPPER.communication[ModemType.EHS8].radioBand_2G,
              suppressPropertyStateTransform: true,
              transformValue: ({ field, modemRadioBands, value }) => {
                const m = DeviceHelpers.getCommunicationModemByType(ModemType.EHS8);
                return DeviceHelpers.getCommunicationRadioBand(field.path, value, m, modemRadioBands);
              }
            }
          ]
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'alarm',
      children: [
        {
          name: 'defaultSensorLevel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.defaultSensorLevel,
          suppressPropertyStateTransform: true,
          transformValue: ({ value }) => ModbusTableHelpers.getSeverityByValue(value)
        },
        {
          name: 'fillingAlarmLevel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.fillingAlarmLevel,
          suppressPropertyStateTransform: true,
          transformValue: ({ value }) => ModbusTableHelpers.getSeverityByValue(value)
        },
        {
          name: 'maintenanceAlarmLevel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.maintenanceAlarmLevel,
          suppressPropertyStateTransform: true,
          transformValue: ({ value }) => ModbusTableHelpers.getSeverityByValue(value)
        },
        {
          name: 'transportAlarmLevel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.transportAlarmLevel,
          suppressPropertyStateTransform: true,
          transformValue: ({ value }) => ModbusTableHelpers.getSeverityByValue(value)
        },
        {
          name: 'energyAlarmLevel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.energyAlarmLevel,
          suppressPropertyStateTransform: true,
          transformValue: ({ value }) => ModbusTableHelpers.getSeverityByValue(value)
        },
        {
          name: 'powerAlarmLevel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.powerAlarmLevel,
          suppressPropertyStateTransform: true,
          transformValue: ({ value }) => ModbusTableHelpers.getSeverityByValue(value)
        },
        {
          name: 'defaultSensorClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.defaultSensorClass
        },
        {
          name: 'fillingAlarmClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.fillingAlarmClass
        },
        {
          name: 'maintenanceAlarmClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.maintenanceAlarmClass
        },
        {
          name: 'transportAlarmClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.transportAlarmClass
        },
        {
          name: 'energyAlarmClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.energyAlarmClass
        },
        {
          name: 'powerSupplyAlarm',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.powerSupplyAlarm
        },
        {
          name: 'powerAlarmClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm.powerAlarmClass
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'gps',
      children: [
        {
          name: 'longitude',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.gps.longitude
        },
        {
          name: 'latitude',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.gps.latitude
        },
        {
          name: 'cardinalLongitude',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.gps.cardinalLongitude,
          mapped: true,
          modbusList: ModbusListName.LstLong
        },
        {
          name: 'cardinalLatitude',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.gps.cardinalLatitude,
          modbusList: ModbusListName.LstLat,
          mapped: true
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'hardware',
      children: [
        {
          name: 'bleChipMacAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.hardware.bleChipMacAddress
        },
        {
          name: 'bleSoftwareVersion',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.hardware.bleSoftwareVersion
        },
        {
          name: 'modemRevision',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.hardware.modemRevision
        },
        {
          name: 'modemARevision',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.hardware.modemARevision
        },
        {
          name: 'hardwareVersion',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.hardware.hardwareVersion,
          mapped: true,
          modbusList: ModbusListName.LstHardVersion
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'tank',
      children: [
        {
          name: 'rollingTankEnable',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.tank.rollingTankEnable
        },
        {
          name: 'rollingTankPeriod',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.tank.rollingTankPeriod
        }
      ]
    }
  ];

  get dataMapping() {
    return this.DATA_MAPPING;
  }

  get stateDataMapping() {
    return this.STATE_DATA_MAPPING;
  }

  get fields(): Field[] {
    return this.FIELDS;
  }

  getMappingModel(options: Partial<FieldMappingModelOptions<DeviceDetails>>): DeviceSettings {
    const status = DeviceConfigurationHelpers.getConfigurationStatus(options?.configuration);
    const valueGetter = (k: string) => ['current', k, 'v'];
    return super.applyMapping({ ...options, valueGetter, status });
  }

  getConfigurationState(data: DeviceDetails, modbusTable: ModbusTable): DeviceConfigurationState {
    const configuration = get(data, ['configuration']);
    const status = DeviceConfigurationHelpers.getConfigurationStatus(configuration);
    return super.getConfigurationState(data, modbusTable, status);
  }

  getPropertyState(field: Field, device: DeviceDetails, modbusTable: ModbusTable): ModbusTablePropertyState {
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = field.path;
    const configuration = get(device, ['configuration'], {}) as DeviceConfiguration;
    const deviceStatus = DeviceConfigurationHelpers.getConfigurationStatus(configuration);
    const collectionName = deviceStatus === DeviceConfigurationStatus.PUBLISHED ? 'target' : 'pending';

    const valueGetter = (k: string) => ['current', k, 'v'];

    let oldValue = get(configuration, ['current', attrPath, 'v'], null);
    const newValue = get(configuration, [collectionName, attrPath], null);

    if (field.transformValue && !field?.suppressPropertyStateTransform) {
      oldValue = field.transformValue({
        field,
        modbusTable,
        data: device,
        value: oldValue,
        configuration,
        valueGetter
      });
    }

    if (field.computed) {
      // In case computed values are impacted by removed variables
      // We change their status to DELETED
      // oldValue === Old index
      // newValue === new index

      const currentIndexes = DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index);

      if (oldValue === 0 && newValue === null) {
        oldValue = null;
      }

      const noOldValueAndHasNewValue = oldValue === null && newValue !== null;
      const noNewValueAndHasOldValue = newValue === null && oldValue !== null;
      const hasBoth = oldValue !== null && newValue !== null;

      const noOldIndexAndNewIndexRemoved = noOldValueAndHasNewValue && !currentIndexes.includes(newValue);
      const hasBothAndNewIndexRemoved = hasBoth && !currentIndexes.includes(newValue);
      const noNewIndexAndOldIndexRemoved = noNewValueAndHasOldValue && !currentIndexes.includes(oldValue);

      // Optional variables could be unused
      // In this case variable index will be 0
      const isVariablePUnused = field.optional && ((oldValue === 0 && newValue === null) || newValue === 0);

      // Unused variables should not be considered as removed
      if (!isVariablePUnused && (noOldIndexAndNewIndexRemoved || noNewIndexAndOldIndexRemoved || hasBothAndNewIndexRemoved)) {
        status = DeviceConfigurationStatus.DELETED;
      }

      if (!field?.suppressPropertyStateCompute) {
        // Set old computed value
        oldValue = this.getComputedProperty(field, oldValue, get(device, ['configuration'], {}) as never, valueGetter);
      }
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && oldValue !== newValue) {
      status = deviceStatus;
    }

    const displayValue = this.getPropertyStateDisplayOldValue(field, modbusTable, device, oldValue, configuration, valueGetter);
    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName: field.name,
      parentAttrName: field?.parentField?.name,
      key: field.path
    };
  }
}
