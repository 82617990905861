export class DuplicateUtils {
  static duplicateValueString(value: string, list: string[], prefix: string = 'Copy', separator: string = '_', maxLength?: number): string {
    const pl = `${prefix}${separator}`.length;
    const sl = separator.length + 1;
    const vl = value.replace(`${prefix}${separator}`, '').length;
    const l = pl + vl + sl;
    if (maxLength && l > maxLength) {
      value = value.substring(0, vl - (pl + sl));
    }

    let element = value;
    let baseValue = value;
    if (prefix && value.indexOf(prefix) === -1) {
      baseValue = `${prefix}${separator}${value}`;
    }
    // First, check if the value exists directly
    if (!list.includes(baseValue)) {
      return baseValue; // If the base value doesn't exist, return it
    }

    // Find all values that match the pattern "<Copy>_<value>_<number>"
    let highestNumber = 0;
    // Loop through the list of existing names and extract the number if it matches the pattern
    list.forEach((item) => {
      // Check if the item starts with "Copy_value_" and ends with a valid number
      // if (item.startsWith(`${baseValue}${separator}`)) {
      if (item.startsWith(`${baseValue}`)) {
        let numberPart = item.split(separator).pop();
        // check for only digits
        if (!(/^\d+$/.test(numberPart))) {
          numberPart = null;
        }
        // Get the last part after the last separator
        const v = Math.round(parseInt(numberPart));
        if (!isNaN(v)) {
          element = item;
          highestNumber = Math.max(highestNumber, v); // Keep track of the highest number
        }
      }
    });
    // Check if the highest item match the base value and ends with the highestNumber
    if (baseValue.replace(`${prefix}${separator}`, '') === element.replace(`${prefix}${separator}`, '') && baseValue.endsWith(`${separator}${highestNumber}`)) {
      // Remove the suffix number
      const parts = element.split(separator);
      parts.pop();
      // Recalculate the next value
      return this.duplicateValueString(parts.join(separator), list, prefix, separator, maxLength);
    }
    // Return the next number in the sequence
    return `${baseValue}${separator}${highestNumber + 1}`;
  }
}
