import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { ArrayUtils, DateIntervalUtils, DecimalUtils } from '@iot-platform/iot-platform-utils';
import { Duration } from '@iot-platform/models/common';
import {
  CommunicationList,
  CommunicationRadioBand,
  CommunicationRadioBandListItem,
  DeviceConfiguration,
  DeviceConfigurationState,
  DeviceConfigurationStatus,
  DeviceDetails,
  DeviceSettings,
  EnergyMode,
  EnergyModes,
  EnergyModeSettings,
  EnergySettings,
  ModbusListItem,
  ModbusTable,
  ModemRadioBands
} from '@iot-platform/models/dalia';
import { get } from 'lodash';
import * as moment from 'moment';
import { DeviceConfigurationHelpers } from './device-configuration.helpers';
import { DeviceMapper } from './device.mapper';

export class DeviceHelpers {
  static readonly REFRESH_COMMAND_ELIGIBLE_OPERATORS = ['Orange France', 'Vodafone WW'];

  static mapper = new DeviceMapper();

  static getEnergySettings = (device: DeviceDetails, energyMode?: EnergyMode) => {
    const mode = DeviceHelpers.getEnergyMode(device, energyMode);
    const settings = this.getDeviceSettings(device, null, null);
    return {
      ...settings.energy,
      mode
    } as EnergySettings;
  };

  static getLastCallTime = (device: DeviceDetails): Duration | null => {
    const energy: EnergySettings = DeviceHelpers.getEnergySettings(device);
    const currentEnergy = get(energy, [energy.mode?.label], {}) as EnergyModeSettings;
    const lastCallStatusTimeStr = get(device, 'lastCallStatus.datetime');
    const currentDateTime = moment();
    const baseLastCallStatusTime = moment(lastCallStatusTimeStr);
    const isValidDate = !!lastCallStatusTimeStr && baseLastCallStatusTime.isValid();
    const hasTransmissionPeriod = currentEnergy.transmissionPeriod !== undefined && currentEnergy.transmissionPeriod !== null;
    if (isValidDate && hasTransmissionPeriod) {
      const lastCallStatusDateTime = baseLastCallStatusTime.clone().add(currentEnergy.transmissionPeriod, 'seconds');
      const lastCallTimeStamp = lastCallStatusDateTime.toDate().getTime();
      const currentTimestamp = currentDateTime.toDate().getTime();
      // Calculate diff only if the last call date time is greater than actual time
      if (currentTimestamp < lastCallTimeStamp) {
        return DateIntervalUtils.diffDates(currentDateTime, lastCallStatusDateTime);
      }
    }
    return null;
  };

  static canSendRefreshCommand = (device: DeviceDetails): boolean => {
    const operator = get(device, ['communication', 'operator']);
    return DeviceHelpers.REFRESH_COMMAND_ELIGIBLE_OPERATORS.includes(operator);
  };

  static getEnergyMode = (device: DeviceDetails, energyMode?: EnergyMode): ModbusListItem => {
    const updatedDevice = DeviceConfigurationHelpers.mergeDeviceConfiguration(device);
    const powerModeValue = DeviceHelpers.getPowerModeLastValue(updatedDevice);
    let mode = ModbusTableHelpers.getEnergyModes().find((v) => v.value === powerModeValue);
    if (energyMode) {
      mode = ModbusTableHelpers.getEnergyModes().find((v) => v.label === energyMode.toString());
    }
    return mode;
  };

  static toEnergyModes = (energyModes: EnergyModes): ModbusListItem[] =>
    Object.keys(energyModes).map((value) => ModbusTableHelpers.getEnergyModes().find(({ label }) => label === value));

  static toEnergySettings = (
    energyModes: EnergyModes
  ): {
    [key: string]: {
      key: string;
      value: Partial<EnergySettings>;
    }[];
  } =>
    DeviceHelpers.toEnergyModes(energyModes).reduce((acc, mode) => {
      const element = energyModes[mode.label];
      const values = Object.keys(element).map((key) => {
        const item = get(element, [key]);
        return {
          key,
          value: {
            mode,
            acquisitionPeriod: get(item, this.mapper.ENERGY_ATTRIBUTE_MAPPER.acquisitionPeriod(mode.label)),
            transmissionPeriod: get(item, this.mapper.ENERGY_ATTRIBUTE_MAPPER.transmissionPeriod(mode.label)),
            recordPeriod: get(item, this.mapper.ENERGY_ATTRIBUTE_MAPPER.recordPeriod(mode.label)),
            diagnosticPeriod: get(item, this.mapper.ENERGY_ATTRIBUTE_MAPPER.diagnosticPeriod(mode.label)),
            listeningTime: get(item, this.mapper.ENERGY_ATTRIBUTE_MAPPER.listeningTime(mode.label))
          }
        };
      });
      return { ...acc, [mode.label]: values };
    }, {});

  static getCallingHour = (startingDate: string, modbusTable: ModbusTable): ModbusListItem => {
    const callingHours = ModbusTableHelpers.getCallingHours(modbusTable);
    const h = moment(startingDate).utc().hours();
    return (
      callingHours?.find(({ value }) => value === h) ?? {
        label: `${h}h UTC`,
        value: h
      }
    );
  };

  static getCommunicationModemByType = (modemType: string) =>
    ModbusTableHelpers.getModemTypes().find(({ label }) => label?.toLowerCase()?.trim() === modemType?.toLowerCase()?.trim());

  static getCommunicationRadioBand = (key: string, decimal: number, modem: ModbusListItem, modemRadioBands: ModemRadioBands): CommunicationRadioBand => {
    const radioBands = get(modemRadioBands, [modem?.label, key, 'radioBands'], []);
    const length = radioBands.length;
    let binaryDigits = Array.from({ length }, () => 0);
    let binary = '';
    if (decimal !== null && decimal !== undefined) {
      binary = DecimalUtils.toBinary(Number(decimal));
      const arr = Array.from(binary)
        .map((v: string) => parseInt(v))
        .reverse();
      binaryDigits = ArrayUtils.padEnd(arr, length, 0);
    }
    return {
      decimal,
      binary,
      binaryDigits,
      radioBands
    };
  };

  static getCommunicationRadioBandsList = (
    settings: DeviceSettings,
    configurationState: DeviceConfigurationState,
    modemRadioBands: ModemRadioBands
  ): CommunicationList => {
    const getRadioBandsByKey = (modem: ModbusListItem, key: string): CommunicationRadioBandListItem[] => {
      const item = get(settings, ['communication', modem?.label, key]);
      const state = get(configurationState, ['communication', 'attributes', modem?.label, 'attributes', key]);
      const oldItem = this.getCommunicationRadioBand(
        get(this.mapper.COMMUNICATION_ATTRIBUTE_MAPPER, [modem?.label, key]),
        state?.oldValue,
        modem,
        modemRadioBands
      );
      return item?.radioBands?.reduce((acc, value, index) => {
        if (value !== 'unused' && value !== null && value !== undefined) {
          const oldBinary = oldItem?.binaryDigits[index];
          const newBinary = item?.binaryDigits[index];
          return [
            ...acc,
            {
              value,
              status: oldBinary === newBinary ? DeviceConfigurationStatus.CURRENT : state?.status,
              checked: newBinary === 1
            }
          ];
        }
        return [...acc];
      }, []);
    };
    return Object.entries(this.mapper.COMMUNICATION_ATTRIBUTE_MAPPER).reduce(
      (acc, [modemType, v1]) => ({
        ...acc,
        [modemType]: Object.entries(v1).reduce((acc1, [key]) => {
          const m = this.getCommunicationModemByType(modemType);
          const radioBands = getRadioBandsByKey(m, key);
          const obj = {
            key,
            radioBands
          };
          return [...acc1, obj];
        }, [])
      }),
      {}
    );
  };

  static getDeviceSettings = (device: DeviceDetails, modbusTable: ModbusTable, modemRadioBands: ModemRadioBands): DeviceSettings => {
    const updatedDevice = DeviceConfigurationHelpers.mergeDeviceConfiguration(device);
    return this.mapper.getMappingModel({
      data: updatedDevice,
      modbusTable,
      modemRadioBands,
      configuration: get(updatedDevice, ['configuration'])
    });
  };

  static getDeviceConfigurationState = (device: DeviceDetails, modbusTable: ModbusTable): DeviceConfigurationState =>
    this.mapper.getConfigurationState(device, modbusTable);

  static cleanUpConfiguration = (
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      if (!(config[key] === currentValue)) {
        acc[key] = config[key];
      }
      return { ...acc };
    }, {});
  };

  static readonly isModemOfTypeUnknown = (modemValue: number): boolean => modemValue === 0;

  static readonly isModemOfTypePLS62 = (modemValue: number): boolean => modemValue === 1;

  static readonly isModemOfTypeEXS82 = (modemValue: number): boolean => modemValue === 2;

  static readonly isModemOfTypePLS63 = (modemValue: number): boolean => modemValue === 3;

  static readonly isModemOfTypeESH8 = (modemValue: number): boolean => modemValue === null || modemValue === undefined;

  private static getPowerModeLastValue = (device: DeviceDetails): number => get(device, ['expandedVariables', 'em', 'lastValue', 'value']);
}
