import { computed, Injectable, Signal } from '@angular/core';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceHelpers, DeviceTemplateHelpers } from '@iot-platform/dalia/util/devices';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { CommunicationList, DeviceDetails, DeviceSettings, DeviceTemplate } from '@iot-platform/models/dalia';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { FavoriteViewsActions } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { DeviceTemplatesActions } from '../actions';
import { DeviceTemplatesSelectors } from '../selectors/device-templates.selectors';
import { DevicesFacade } from './devices.facade';

@Injectable({
  providedIn: 'root'
})
export class DeviceTemplatesFacade extends BaseFacade<DeviceTemplate, Pagination, Filter> {
  gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }>;
  grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]>;
  currentGrid = computed(() => this.gridConfiguration()?.currentGrid);
  deletionInProgress: Signal<boolean>;

  currentDevice: Signal<DeviceDetails>;
  deviceSettings: Signal<DeviceSettings>;
  radioBandsList: Signal<CommunicationList>;
  deactivateActions: Signal<boolean>;

  constructor(
    protected override store: Store,
    protected override selector: DeviceTemplatesSelectors,
    protected readonly directoryFacade: DirectoryFacade,
    protected readonly devicesFacade: DevicesFacade
  ) {
    super(store, selector);

    this.gridConfiguration = this.store.selectSignal(fromGrids.selectDaliaDeviceTemplatesGridsConfiguration);
    this.grids = this.store.selectSignal(fromGrids.selectDaliaDeviceTemplatesGrids);
    this.deletionInProgress = this.store.selectSignal(this.selector.selectDeletionInProgress);
    this.gridConfiguration = this.store.selectSignal(fromGrids.selectDaliaDeviceTemplatesGridsConfiguration);
    this.deactivateActions = computed(() => {
      const canUpdateDevice = this.devicesFacade.canUpdateDevice();
      const currentEntity = this.currentEntity();
      return !(!!currentEntity?.isEditable && canUpdateDevice && !this.loading());
    });

    this.currentDevice = computed(() => {
      const currentTemplate = this.currentEntity();
      return {
        id: currentTemplate?.id,
        name: currentTemplate?.name,
        description: currentTemplate?.description,
        configuration: {
          current: DeviceTemplateHelpers.toCurrentConfiguration(get(currentTemplate, 'config', {}))
        }
      } as DeviceDetails;
    });

    this.deviceSettings = computed(() => {
      const device = this.currentDevice();
      const modbusTable = this.directoryFacade.modbusTable();
      const modemRadioBands = this.directoryFacade.modemRadioBands();
      return DeviceHelpers.getDeviceSettings(device, modbusTable, modemRadioBands);
    });

    this.radioBandsList = computed(() => {
      const modemRadioBands = this.directoryFacade.modemRadioBands();
      const settings = this.deviceSettings();
      return DeviceHelpers.getCommunicationRadioBandsList(settings, null, modemRadioBands);
    });
  }

  getAll(request?: CommonApiRequest) {
    this.store.dispatch(DeviceTemplatesActions.loadTemplates({ request }));
  }

  loadTemplateById(id: string) {
    this.store.dispatch(DeviceTemplatesActions.loadTemplateById({ id }));
  }

  setFilters(filters: Filter[]): void {
    this.store.dispatch(DeviceTemplatesActions.setFilters({ filters }));
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'dalia-device-templates', filters }));
  }

  setCurrentTemplate(template: DeviceTemplate): void {
    this.store.dispatch(DeviceTemplatesActions.setCurrentTemplate({ template }));
  }

  createTemplate(template: DeviceTemplate): void {
    this.store.dispatch(DeviceTemplatesActions.createTemplate({ template }));
  }

  duplicateTemplate(template: DeviceTemplate): void {
    this.store.dispatch(DeviceTemplatesActions.duplicateTemplate({ template }));
  }

  editTemplate(template: DeviceTemplate): void {
    this.store.dispatch(DeviceTemplatesActions.editTemplate({ template }));
  }

  deleteTemplate(template: DeviceTemplate): void {
    this.store.dispatch(DeviceTemplatesActions.deleteTemplate({ template }));
  }

  addDeviceTemplateToCurrentGrid(item: DeviceTemplate): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(GridsDbActions.addItemInGridData({ gridId, item }));
    }
  }

  updateDeviceTemplateInCurrentGrid(item: DeviceTemplate): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(
        GridsDbActions.updateItemInGridData({
          gridId,
          item,
          concept: 'dalia-device-templates'
        })
      );
    }
  }

  removeDeviceTemplateFromCurrentGrid(item: DeviceTemplate): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(GridsDbActions.removeItemInGridData({ gridId, item }));
    }
  }
}
