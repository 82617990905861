import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import {
  DeviceConfiguration,
  DeviceDetails,
  DeviceModbusRequestTable,
  DeviceModbusRequestTableState,
  ModbusListItem,
  ModbusTable
} from '@iot-platform/models/dalia';
import { get, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DeviceConfigurationHelpers } from './device-configuration.helpers';
import { DeviceModbusRequestTableMapper } from './device-modbus-request-table.mapper';

export class DeviceModbusRequestTableHelpers {
  static MAX_ALLOWED_INDEX = 5;

  static mapper = new DeviceModbusRequestTableMapper();

  // Return all MRTs from device configurations
  // Exclude deleted MRTs
  static getAllIndexes(configuration: DeviceConfiguration): {
    key: string;
    index: number;
  }[] {
    const currentKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(configuration, 'current', {}));
    const pendingKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(configuration, 'pending', {}));
    const targetKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(configuration, 'target', {}));

    // Remove MRTs to be deleted
    return uniq([...currentKeys, ...pendingKeys, ...targetKeys]).map((key: string) => ({
      key,
      index: DeviceConfigurationHelpers.parseIndex(key)
    }));
  }

  // Generate processed dalia device modbus request table list
  static readonly getDeviceModbusRequestTables = (device: DeviceDetails, modbusTable: ModbusTable): DeviceModbusRequestTable[] => {
    const currentKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(device, ['configuration', 'current'], {}));
    const pendingKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(device, ['configuration', 'pending'], {}));
    const targetKeys: string[] = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableKeysFromConfig(get(device, ['configuration', 'target'], {}));

    // Created new MRTs does not exist in current configuration, they exist in pending configuration instead
    const createdNewMRTKeys = pendingKeys.filter((k) => !currentKeys.includes(k));
    // Updated MRTs exist in both of current and pending configurations
    const updatedMRTKeys = currentKeys.filter((k) => pendingKeys.includes(k));
    // Unmodified MRTs should not exist in created and updated lists
    const currentMRTKeys = currentKeys.filter((k) => !createdNewMRTKeys.includes(k) && !updatedMRTKeys.includes(k));

    const currentMRTs = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableFromConfiguration(
      currentMRTKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const updatedMRTs = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableFromConfiguration(
      updatedMRTKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['current', key, 'v'],
      false
    );

    const createdMRTs = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableFromConfiguration(
      createdNewMRTKeys,
      device,
      modbusTable,
      pendingKeys,
      targetKeys,
      (key) => ['pending', key], // should retrieve properties from pending configuration for created MRTs
      true
    );
    const items = [...currentMRTs, ...updatedMRTs, ...createdMRTs].sort((a, b) => a.index - b.index);
    return items.slice(0, DeviceModbusRequestTableHelpers.MAX_ALLOWED_INDEX);
  };

  static readonly getAvailableNextIndex = (configuration: DeviceConfiguration): number => {
    const indexes = DeviceModbusRequestTableHelpers.getAllIndexes(configuration).map((item) => item.index);
    return DeviceConfigurationHelpers.getAvailableNextIndex(indexes, DeviceModbusRequestTableHelpers.MAX_ALLOWED_INDEX);
  };

  static readonly getAvailableIndexes = (configuration: DeviceConfiguration, currentIndex?: number): ModbusListItem[] => {
    const indexes = DeviceModbusRequestTableHelpers.getAllIndexes(configuration).map((item) => item.index);
    return DeviceConfigurationHelpers.getAvailableIndexes(indexes, DeviceModbusRequestTableHelpers.MAX_ALLOWED_INDEX, currentIndex);
  };

  static readonly getDeviceModbusRequestTableKeysFromConfig = (configuration: { [key: string]: unknown }): string[] => {
    const keys = Object.keys(configuration)
      .filter((key: string) => key.match(/(^MRT\d+(\.)*)./gi))
      .map((key) => key.split('.')[0]);
    return uniq(keys);
  };

  static readonly getDeviceModbusRequestTableFromConfiguration = (
    keys: string[],
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceModbusRequestTable[] =>
    uniq(keys).reduce((acc, key) => {
      const deviceModbusRequestTable = this.getDeviceModbusRequestTableInstance(key, device, modbusTable, pendingKeys, targetKeys, valueGetter, isCreated);
      return [...acc, deviceModbusRequestTable];
    }, []);

  static getDeviceModbusRequestTableState = (deviceModbusRequestTable: DeviceModbusRequestTable, modbusTable: ModbusTable): DeviceModbusRequestTableState =>
    this.mapper.getConfigurationState(deviceModbusRequestTable, modbusTable);

  // Return MRT prefix key by index
  static readonly getDeviceModbusRequestTableIndexKey = (deviceModbusRequestTable: DeviceModbusRequestTable): string =>
    `MRT${StringUtils.padWith(deviceModbusRequestTable?.index, 2, '0')}`;

  // Return a new configuration copy without properties that match MRT index
  static readonly removePropertiesFrom = (
    deviceModbusRequestTable: DeviceModbusRequestTable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(deviceModbusRequestTable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get MRT key
    const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(deviceModbusRequestTable);
    return DeviceConfigurationHelpers.reduceConfiguration(configuration, key, targetConfiguration, false);
  };

  // Return all configuration properties that match MRT index
  static readonly getPropertiesFrom = (
    deviceModbusRequestTable: DeviceModbusRequestTable,
    targetConfiguration: 'current' | 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Create a configuration copy
    const configuration = get(deviceModbusRequestTable, ['device', 'configuration'], {}) as DeviceConfiguration;
    // Get MRT key
    const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(deviceModbusRequestTable);
    return DeviceConfigurationHelpers.reduceConfiguration(configuration, key, targetConfiguration, true);
  };

  // MRT that does not have an index are not configurable
  static readonly canConfigureDeviceModbusRequestTable = (deviceModbusRequestTable: DeviceModbusRequestTable) =>
    deviceModbusRequestTable.index !== null &&
    deviceModbusRequestTable.index !== undefined &&
    !Object.keys(get(deviceModbusRequestTable.device, ['configuration', 'target'], {})).length;

  static readonly cleanUpConfiguration = (
    indexKey: string,
    deviceConfiguration: DeviceConfiguration,
    configuration: { [key: string]: unknown },
    targetConfiguration: 'pending' | 'target'
  ): {
    [key: string]: unknown;
  } => {
    // Override pending configuration
    const config = {
      ...get(deviceConfiguration, [targetConfiguration], {}),
      ...configuration
    };

    // Clean up target configuration
    return Object.keys(config).reduce((acc, key) => {
      const currentValue = get(deviceConfiguration, ['current', key, 'v']);
      if (!(config[key] === currentValue)) {
        acc[key] = config[key];
      }
      return { ...acc };
    }, {});
  };

  static readonly getBaudrateByValue = (key: string, configuration: DeviceConfiguration, valueGetter: (key: string) => string[]) => {
    const baudrate = get(configuration, valueGetter(`${key}.${this.mapper.ATTRIBUTE_MAPPER.baudrate}`));
    return ModbusTableHelpers.getBaudrates().find(({ value }) => value === baudrate);
  };

  private static getDeviceModbusRequestTableInstance(
    key: string,
    device: DeviceDetails,
    modbusTable: ModbusTable,
    pendingKeys: string[],
    targetKeys: string[],
    valueGetter: (key: string) => string[],
    isCreated: boolean
  ): DeviceModbusRequestTable {
    const status = DeviceConfigurationHelpers.getStatusByKey(key, device?.configuration, pendingKeys, targetKeys);
    const index = DeviceConfigurationHelpers.parseIndex(key);

    const uid = uuidv4();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let deviceModbusRequestTable: any = {
      id: uid,
      index,
      status,
      device,
      isCreated
    };

    const mapping = this.mapper.getMappingModel({
      data: deviceModbusRequestTable,
      modbusTable,
      valueGetter,
      configuration: get(deviceModbusRequestTable, ['device', 'configuration'])
    });
    deviceModbusRequestTable = {
      ...deviceModbusRequestTable,
      ...mapping
    };

    // This is used as business rule to enable/disable MRT configuration
    const canConfigure = DeviceModbusRequestTableHelpers.canConfigureDeviceModbusRequestTable(deviceModbusRequestTable);
    const hasCleanConfiguration = this.mapper.hasCleanConfiguration(deviceModbusRequestTable, modbusTable);
    return {
      ...deviceModbusRequestTable,
      canConfigure,
      hasCleanConfiguration
    };
  }
}
