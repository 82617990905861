import { computed, inject, Injectable, Signal } from '@angular/core';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { DirectoryFacade } from '@iot-platform/dalia/data-access';
import { DeviceHelpers } from '@iot-platform/dalia/util/devices';
import { fromGrids } from '@iot-platform/grid-engine';
import { BaseFacade, CommonApiRequest, Duration, Filter, Pagination } from '@iot-platform/models/common';
import {
  CommunicationList,
  DeviceConfigurationState,
  DeviceConfigurationStatus,
  DeviceConfigurationTargetStatus,
  DeviceDetails,
  DeviceSettings
} from '@iot-platform/models/dalia';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { CommandType } from '@iot-platform/models/i4b';
import { FavoriteViewsActions } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DevicesActions } from '../actions';
import { DevicesSelectors } from '../selectors/devices.selectors';

@Injectable({
  providedIn: 'root'
})
export class DevicesFacade extends BaseFacade<DeviceDetails, Pagination, Filter> {
  canUpdateDevice: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    // For DALIA V2 we check only on CREATE rule
    // So the canUpdateDevice should be concidered as canCreateDevice
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
  });
  canCreateDevice: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
  });
  canReadHistory: Signal<boolean> = computed(() => {
    this.authFacade.privileges();
    return this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);
  });
  _deactivateActions: Signal<boolean> = this.store.selectSignal(this.selector.selectDeactivateActions);
  deactivateActions: Signal<boolean> = computed(() => {
    // eslint-disable-next-line no-underscore-dangle
    const deactivateActions = this._deactivateActions();
    const canUpdateDevice = this.canUpdateDevice();
    return !(canUpdateDevice && !deactivateActions);
  });
  configurationTargetStatus: Signal<DeviceConfigurationTargetStatus> = this.store.selectSignal(this.selector.selectConfigurationTargetStatus);
  lastCall: Signal<number> = this.store.selectSignal(this.selector.selectLastCall);
  lastCallDuration: Signal<Duration> = this.store.selectSignal(this.selector.selectLastCallDuration);
  configurationStatus: Signal<DeviceConfigurationStatus> = this.store.selectSignal(this.selector.selectConfigurationStatus);
  lastCallStatusRefreshActivated: Signal<boolean> = this.store.selectSignal(this.selector.selectLastCallStatusRefreshActivated);
  lastCallStatusRefreshActivated$: Observable<boolean> = this.store.select(this.selector.selectLastCallStatusRefreshActivated);
  canSendRefreshCommand: Signal<boolean> = this.store.selectSignal(this.selector.selectCanSendRefreshCommand);
  gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = this.store.selectSignal(fromGrids.selectDaliaDevicesGridsConfiguration);
  grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]> = this.store.selectSignal(fromGrids.selectDaliaDevicesGrids);
  currentGrid = computed(() => this.gridConfiguration()?.currentGrid);
  protected readonly directoryFacade: DirectoryFacade = inject(DirectoryFacade);
  deviceSettings: Signal<DeviceSettings> = computed(() => {
    const device = this.currentEntity();
    const modbusTable = this.directoryFacade.modbusTable();
    const modemRadioBands = this.directoryFacade.modemRadioBands();
    return DeviceHelpers.getDeviceSettings(device, modbusTable, modemRadioBands);
  });
  deviceConfigurationState: Signal<DeviceConfigurationState> = computed(() => {
    const device = this.currentEntity();
    const modbusTable = this.directoryFacade.modbusTable();
    return DeviceHelpers.getDeviceConfigurationState(device, modbusTable);
  });
  radioBandsList: Signal<CommunicationList> = computed(() => {
    const modemRadioBands = this.directoryFacade.modemRadioBands();
    const settings = this.deviceSettings();
    const configurationState = this.deviceConfigurationState();
    return DeviceHelpers.getCommunicationRadioBandsList(settings, configurationState, modemRadioBands);
  });

  constructor(
    protected override store: Store,
    protected override selector: DevicesSelectors,
    protected readonly authFacade: AuthFacade,
    protected readonly authorizationService: AuthorizationService
  ) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest) {
    this.store.dispatch(DevicesActions.loadDevices({ request }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(DevicesActions.setFilters({ filters }));
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'dalia-devices', filters }));
  }

  setLastCallDuration(duration: Duration) {
    this.store.dispatch(DevicesActions.setLastCallDuration({ duration }));
  }

  setLastCallStatusRefreshActivated(value: boolean) {
    this.store.dispatch(DevicesActions.setLastCallStatusRefreshActivated({ value }));
  }

  loadDeviceById(id: string): void {
    this.store.dispatch(DevicesActions.loadDeviceById({ id }));
  }

  updateDevice(device: DeviceDetails): void {
    this.store.dispatch(DevicesActions.updateDevice({ device }));
  }

  setCurrentDevice(device: DeviceDetails): void {
    this.store.dispatch(DevicesActions.setCurrentDevice({ device }));
  }

  sendCommand(device: DeviceDetails, command: CommandType): void {
    this.store.dispatch(DevicesActions.sendCommand({ device, command }));
  }
}
