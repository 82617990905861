import { NgModule } from '@angular/core';
import { VariableChartDialogComponent } from './variable-chart-dialog/variable-chart-dialog.component';
import { VariableChartComponent } from './variable-chart.component';
import { VariableItemComponent } from './variable-item/variable-item.component';

@NgModule({
  imports: [VariableChartComponent, VariableItemComponent, VariableChartDialogComponent],
  exports: [VariableChartComponent, VariableItemComponent, VariableChartDialogComponent]
})
export class VariableChartModule {}
