import { NgModule } from '@angular/core';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { TabImageComponent } from './tab-image/tab-image.component';
import { TabComponent } from './tab/tab.component';

const COMPONENTS = [TabGroupComponent, TabComponent, TabHeaderComponent, TabContentComponent, TabImageComponent];

@NgModule({
  imports: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class TabsModule {}
