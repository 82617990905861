import { ModbusTableHelpers } from '@iot-platform/dalia/util';
import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceVariable as DaliaDeviceVariable,
  DeviceVariableState,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState
} from '@iot-platform/models/dalia';

import { get } from 'lodash';
import { AbstractMapper, Field, FieldMappingModelOptions, FieldType, VARIABLE_NAME_ATTRIBUTE_MAPPER } from './abstract-mapper';
import { DeviceTankHelpers } from './device-tank.helpers';
import { DeviceVariableHelpers } from './device-variable.helpers';

export class DeviceVariableMapper extends AbstractMapper<DaliaDeviceVariable, DaliaDeviceVariable, DeviceVariableState> {
  readonly ATTRIBUTE_MAPPER = {
    kind: 'Kind',
    name: VARIABLE_NAME_ATTRIBUTE_MAPPER,
    comment: 'Comment',
    unitCode: 'Unit_code',
    path: 'Path',
    channel: 'Channel',
    slaveModbusAddress: 'Multi.Modbus.slave@/CALC.index_var1/ADC.type',
    dataModbusAddress: 'Multi.Modbus.data@/CALC.index_var2_or_derive_period',
    inputVariable1: 'Multi.Modbus.slave@/CALC.index_var1/ADC.type',
    drasticChangeAsset: 'Multi.Modbus.slave@/CALC.index_var1/ADC.type',
    inputVariable2: 'Multi.Modbus.data@/CALC.index_var2_or_derive_period',
    alarm: 'Alarm.type',
    relay: 'Alarm.relay',
    lowerThreshold: 'Alarm.lower_threshold',
    upperThreshold: 'Alarm.upper_threshold',
    lowerThresholdSeverity: 'Alarm.severity_lower_threshold',
    upperThresholdSeverity: 'Alarm.severity_upper_threshold',
    lowerThresholdClass: 'Alarm.class_lower_threshold',
    upperThresholdClass: 'Alarm.class_upper_threshold',
    type: 'Data_type',
    warmup: 'Warmup_time',
    slope: 'Slope',
    offset: 'Offset',
    decimalPrecision: 'Decimal_precision',
    modbusParity: 'Modbus.parity',
    modbusBaudrate: 'Modbus.baudrate',
    modbusStopBits: 'Modbus.stop_bits',
    modbusFunction: 'Modbus.function',
    modbusType: 'Modbus.tpe',
    recordMode: 'Record_mode',
    alarmHysteresis: 'Alarm.hysteresis',
    alarmReserved: 'Alarm.reserved',
    modbusBitmask: 'Modbus.bitmask',
    transmissionScaler: 'Transmission_scaler',
    acquisitionScaler: 'Acquisition_scaler',
    modbusVariableToCopy: 'Modbus.variable_to_copy'
  };

  readonly STATE_DATA_MAPPING = {
    generalInformation: {
      kind: true,
      name: true,
      comment: true,
      unitCode: true,
      path: true,
      channel: true,
      slaveModbusAddress: true,
      dataModbusAddress: true,
      inputVariable1: true,
      inputVariable2: true,
      drasticChangeAsset: true,
      modbusVariableToCopy: true
    },
    deviceEvent: {
      alarm: true,
      relay: true,
      lowerThreshold: true,
      upperThreshold: true,
      lowerThresholdSeverity: true,
      upperThresholdSeverity: true,
      lowerThresholdClass: true,
      upperThresholdClass: true,
      alarmHysteresis: true
    },
    advancedInformation: {
      type: true,
      decimalPrecision: true,
      slope: true,
      offset: true,
      warmup: true,
      slaveModbusAddress: true,
      dataModbusAddress: true,
      modbusParity: true,
      modbusBaudrate: true,
      modbusStopBits: true,
      modbusFunction: true,
      modbusType: true,
      modbusBitmask: true
    },
    otherInformation: {
      recordMode: true,
      transmissionScaler: true,
      acquisitionScaler: true,
      alarmReserved: true
    }
  };

  readonly DATA_MAPPING = {
    kind: true,
    name: true,
    comment: true,
    unitCode: true,
    path: true,
    channel: true,
    slaveModbusAddress: true,
    dataModbusAddress: true,
    inputVariable1: true,
    inputVariable2: true,
    drasticChangeAsset: true,
    modbusVariableToCopy: true,
    alarm: true,
    relay: true,
    lowerThreshold: true,
    upperThreshold: true,
    lowerThresholdSeverity: true,
    upperThresholdSeverity: true,
    lowerThresholdClass: true,
    upperThresholdClass: true,
    alarmHysteresis: true,
    type: true,
    decimalPrecision: true,
    slope: true,
    offset: true,
    warmup: true,
    modbusParity: true,
    modbusBaudrate: true,
    modbusStopBits: true,
    modbusFunction: true,
    modbusType: true,
    recordMode: true,
    modbusBitmask: true,
    transmissionScaler: true,
    acquisitionScaler: true,
    alarmReserved: true
  };

  readonly FIELDS: Field[] = [
    {
      type: FieldType.COMPLEX,
      name: 'generalInformation',
      children: [
        {
          name: 'kind',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.kind,
          modbusList: ModbusListName.LstKindType,
          mapped: true
        },
        { name: 'name', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.name },
        { name: 'comment', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.comment },
        {
          name: 'unitCode',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.unitCode,
          modbusList: ModbusListName.LstUnitEnum,
          mapped: true
        },
        {
          name: 'path',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.path,
          modbusList: ModbusListName.LstPathEnum,
          mapped: true
        },
        {
          name: 'channel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.channel,
          modbusList: ModbusListName.LstChannelInternal, // By default internal list
          mapped: true,
          transformField: ({ field, data: variable, configuration, valueGetter }) => {
            const key = DeviceVariableHelpers.getVariableIndexKey(variable);
            const kindKey = `${key}.${this.ATTRIBUTE_MAPPER.kind}`;
            const kind = get(configuration, valueGetter(kindKey));
            // Set channel list name
            return {
              ...field,
              modbusList: ModbusTableHelpers.getChannelListName(kind)
            };
          }
        },
        {
          name: 'slaveModbusAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.slaveModbusAddress
        },
        {
          name: 'dataModbusAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.dataModbusAddress
        },
        {
          name: 'inputVariable1',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.inputVariable1,
          computed: true,
          computedVariable: true,
          optional: true,
          suppressPropertyStateTransform: true,
          suppressPropertyCompute: true, // The transformValue callback do the computation
          transformValue: ({ value, field, data: variable, configuration, valueGetter }) => {
            const key = DeviceVariableHelpers.getVariableIndexKey(variable);
            const kindKey = `${key}.${this.ATTRIBUTE_MAPPER.kind}`;
            const kind = get(configuration, variable?.isCreated ? ['pending', kindKey] : ['current', kindKey, 'v']);
            if (ModbusTableHelpers.hasTank(kind)) {
              return this.computeSlaveModbusAddressOfTypeTank(key, configuration, valueGetter);
            }
            return this.getComputedProperty(field, value, configuration, valueGetter, key);
          }
        },
        {
          name: 'inputVariable2',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.inputVariable2,
          computed: true,
          computedVariable: true,
          optional: true,
          suppressPropertyStateTransform: true,
          suppressPropertyCompute: true, // The transformValue callback do the computation
          transformValue: ({ value, field, data: variable, configuration, valueGetter }) => {
            const key = DeviceVariableHelpers.getVariableIndexKey(variable);
            const kindKey = `${key}.${this.ATTRIBUTE_MAPPER.kind}`;
            const kind = get(configuration, variable?.isCreated ? ['pending', kindKey] : ['current', kindKey, 'v']);
            if (ModbusTableHelpers.hasTank(kind)) {
              return this.computeDataModbusAddressOfTypeTank(key, configuration, valueGetter);
            }
            return this.getComputedProperty(field, value, configuration, valueGetter, key);
          }
        },
        {
          name: 'drasticChangeAsset',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.drasticChangeAsset,
          computed: true,
          computedVariable: true,
          optional: true,
          suppressPropertyStateTransform: true,
          suppressPropertyCompute: true, // The transformValue callback do the computation
          transformValue: ({ value, data: variable, configuration, valueGetter }) => {
            const indexKey = DeviceVariableHelpers.getVariableIndexKey(variable);
            const kindKey = `${indexKey}.${this.ATTRIBUTE_MAPPER.kind}`;
            const kind = get(configuration, variable?.isCreated ? ['pending', kindKey] : ['current', kindKey, 'v']);
            if (ModbusTableHelpers.hasDrasticChange(kind)) {
              return this.computeSlaveModbusAddressOfTypeTank(indexKey, configuration, valueGetter);
            }
            return value;
          }
        },
        {
          name: 'modbusVariableToCopy',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusVariableToCopy,
          computed: true,
          computedVariable: true,
          optional: true
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'deviceEvent',
      children: [
        {
          name: 'alarm',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarm,
          modbusList: ModbusListName.LstAlarmType,
          mapped: true
        },
        {
          name: 'relay',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.relay,
          modbusList: ModbusListName.LstRelayAction,
          mapped: true
        },
        {
          name: 'lowerThreshold',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.lowerThreshold
        },
        {
          name: 'upperThreshold',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.upperThreshold
        },
        {
          name: 'lowerThresholdSeverity',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.lowerThresholdSeverity
        },
        {
          name: 'upperThresholdSeverity',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.upperThresholdSeverity
        },
        {
          name: 'lowerThresholdClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.lowerThresholdClass
        },
        {
          name: 'upperThresholdClass',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.upperThresholdClass
        },
        {
          name: 'alarmHysteresis',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.alarmHysteresis
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'advancedInformation',
      children: [
        {
          name: 'type',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.type,
          modbusList: ModbusListName.LstTypeEnum,
          mapped: true
        },
        {
          name: 'decimalPrecision',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.decimalPrecision
        },
        { name: 'slope', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.slope },
        { name: 'offset', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.offset },
        { name: 'warmup', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.warmup },
        {
          name: 'slaveModbusAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.slaveModbusAddress
        },
        {
          name: 'dataModbusAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.dataModbusAddress
        },
        {
          name: 'modbusParity',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusParity,
          modbusList: ModbusListName.LstParity,
          mapped: true
        },
        {
          name: 'modbusBaudrate',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusBaudrate
        },
        {
          name: 'modbusStopBits',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusStopBits,
          modbusList: ModbusListName.LstMbStopbits,
          mapped: true
        },
        {
          name: 'modbusFunction',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusFunction,
          modbusList: ModbusListName.LstFctMB,
          mapped: true
        },
        {
          name: 'modbusType',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusType,
          modbusList: ModbusListName.LstMbType,
          mapped: true
        },
        { name: 'modbusBitmask', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.modbusBitmask }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'otherInformation',
      children: [
        {
          name: 'recordMode',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.recordMode,
          modbusList: ModbusListName.LstRecMode,
          mapped: true
        },
        {
          name: 'transmissionScaler',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.transmissionScaler
        },
        {
          name: 'acquisitionScaler',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.acquisitionScaler
        },
        { name: 'alarmReserved', type: FieldType.BASIC, path: this.ATTRIBUTE_MAPPER.alarmReserved }
      ]
    }
  ];

  get fields(): Field[] {
    return this.FIELDS;
  }

  get stateDataMapping() {
    return this.STATE_DATA_MAPPING;
  }

  get dataMapping() {
    return this.DATA_MAPPING;
  }

  getPropertyState = (field: Field, data: DaliaDeviceVariable, modbusTable: ModbusTable): ModbusTablePropertyState => {
    const key = DeviceVariableHelpers.getVariableIndexKey(data);
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = `${key}.${field.path}`;
    let newCollectionName = 'pending';
    if (data?.status === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const valueGetter = (k: string) => (data?.isCreated ? [newCollectionName, k] : ['current', k, 'v']);

    const configuration = get(data, ['device', 'configuration'], {}) as DeviceConfiguration;

    let kind = parseInt(get(configuration, valueGetter(`${key}.${this.ATTRIBUTE_MAPPER.kind}`)) as string);
    // For created variables we don't have an old kind
    if (data?.isCreated) {
      kind = parseInt(get(configuration, [newCollectionName, `${key}.${this.ATTRIBUTE_MAPPER.kind}`]) as string);
    }

    const hasTank = ModbusTableHelpers.hasTank(kind);
    const hasDrasticChange = ModbusTableHelpers.hasDrasticChange(kind);

    if (field.transformField) {
      field = field.transformField({ field, data, modbusTable, configuration, valueGetter });
    }

    let oldValue = get(configuration, valueGetter(attrPath), null);
    const newValue = get(configuration, [newCollectionName, attrPath], null);

    if (field.transformValue && !field?.suppressPropertyStateTransform) {
      const v = field.transformValue({
        field,
        modbusTable,
        data,
        value: oldValue,
        configuration,
        valueGetter
      });
      oldValue = get(v, ['value'], v);
    }

    if (field.computed) {
      if (!(hasTank && field.name === 'inputVariable2')) {
        const currentIndexes = !(hasTank || hasDrasticChange)
          ? DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index)
          : DeviceTankHelpers.getAllTankIndexes(configuration, true).map((item) => item.index);

        // In case computed values are impacted by removed variables or tanks
        // We change their status to DELETED
        // oldValue === Old index
        // newValue === new index
        const hasOldIndexAndNewIndexRemoved = oldValue === null && newValue !== null && !currentIndexes.includes(newValue);
        const hasNotOldIndexAndNewIndexRemoved = oldValue !== null && newValue !== null && !currentIndexes.includes(newValue);
        const hasNewIndexAndOldIndexRemoved = oldValue !== null && newValue === null && !currentIndexes.includes(oldValue);

        if (hasOldIndexAndNewIndexRemoved || hasNotOldIndexAndNewIndexRemoved || hasNewIndexAndOldIndexRemoved) {
          status = DeviceConfigurationStatus.DELETED;
        }
      }

      if (!field?.suppressPropertyStateCompute) {
        // Set old computed value
        oldValue = this.getComputedProperty(field, oldValue, configuration, valueGetter, key);
      }
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && (data?.isCreated || oldValue !== newValue)) {
      status = data.status;
    }
    if (data?.isCreated && field.name === 'alarm' && newValue === 0 && oldValue === 0) {
      status = DeviceConfigurationStatus.CURRENT;
    }

    const displayValue = this.getPropertyStateDisplayOldValue(field, modbusTable, data, oldValue, configuration, valueGetter, key);

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName: field.name,
      parentAttrName: field?.parentField?.name,
      key: attrPath
    };
  };

  getMappingModel(options: Partial<FieldMappingModelOptions<DaliaDeviceVariable>>): DaliaDeviceVariable {
    const pathPrefix = DeviceVariableHelpers.getVariableIndexKey(options.data);
    const status = get(options, ['data', 'status'], DeviceConfigurationStatus.CURRENT);
    return super.applyMapping({ ...options, pathPrefix, status, mergeConfig: true });
  }

  hasCleanConfiguration(variable: DaliaDeviceVariable, modbusTable: ModbusTable): boolean {
    const fields = this.fields;
    const flattened = this.flattenFields(fields);
    const hasDeletedProp = flattened.some((field) => {
      const state = this.getPropertyState(field, variable, modbusTable);
      if (field.computed) {
        const hasTankForInputVariable1 = field.name === 'inputVariable1' && ModbusTableHelpers.hasTank(variable.kind?.value);
        const hasTankForInputVariable2 = field.name === 'inputVariable2' && ModbusTableHelpers.hasTank(variable.kind?.value);
        const hasInputVar1 = field.name === 'inputVariable1' && ModbusTableHelpers.hasInputVar1(variable.kind?.value);
        const hasInputVar2 = field.name === 'inputVariable2' && ModbusTableHelpers.hasInputVar2(variable.kind?.value);
        const hasDrasticChangeForDrasticChangeAsset = field.name === 'drasticChangeAsset' && ModbusTableHelpers.hasDrasticChange(variable.kind?.value);
        const hasVariableToCopy =
          field.name === 'modbusVariableToCopy' && ModbusTableHelpers.hasVariableToCopy(variable.kind?.value, variable.modbusFunction?.value);
        if (
          hasTankForInputVariable1 ||
          hasTankForInputVariable2 ||
          hasDrasticChangeForDrasticChangeAsset ||
          hasInputVar1 ||
          hasInputVar2 ||
          hasVariableToCopy
        ) {
          return get(state, ['status']) === DeviceConfigurationStatus.DELETED;
        }
        return false;
      }
      return get(state, ['status']) === DeviceConfigurationStatus.DELETED;
    });
    return !hasDeletedProp;
  }

  computeDataModbusAddressOfTypeTank(key: string, configuration: DeviceConfiguration, valueGetter: (key: string) => string[]) {
    const dataModbusAddress = get(configuration, valueGetter(`${key}.${this.ATTRIBUTE_MAPPER.dataModbusAddress}`));
    return ModbusTableHelpers.getVariableTypes().find((v) => v.value === dataModbusAddress)?.label;
  }

  computeSlaveModbusAddressOfTypeTank(key: string, configuration: DeviceConfiguration, valueGetter: (key: string) => string[]) {
    const slaveModbusAddress = get(configuration, valueGetter(`${key}.${this.ATTRIBUTE_MAPPER.slaveModbusAddress}`));
    return slaveModbusAddress !== null && slaveModbusAddress !== undefined ? `#${slaveModbusAddress}` : slaveModbusAddress;
  }
}
